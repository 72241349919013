import * as React from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { tombac } from 'tombac';

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const CursorHintContainer = styled.div`
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  padding: ${tombac.space(0.5, 1)};
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  margin: 8px 20px;
  color: #fff;
  font-size: 12px;
  animation: ${appear} 0.2s ease-in-out;
`;
export function CursorHint({ children }) {
  const ref = React.useRef<HTMLDivElement>();

  React.useLayoutEffect(() => {
    const onFrame = (e: MouseEvent) => {
      if (!ref.current) return;
      const el = ref.current;
      const x = e.clientX;
      const y = e.clientY;

      el.style.transform = `translate(${x}px, ${y}px)`;
    };

    document.body.addEventListener('mousemove', onFrame);

    return () => {
      document.body.removeEventListener('mousemove', onFrame);
    };
  }, []);
  return <CursorHintContainer ref={ref}>{children}</CursorHintContainer>;
}
