import { useEffect, useState } from 'react';
import useLayers from 'hooks/useLayers';
import useMap from 'hooks/useMap';
import { RegionDto } from 'model/RegionDto';
import RoadCoverageApi from 'api/RoadCoverageApi';
import throttle from 'lodash/throttle';
import { useMenu } from 'reducers/menuReducer';
import { AnalysisType } from 'model/AnalysisDto';
import { isSelectedLinkRegionSelected } from 'components/SelectedLinkPage/utils';
import { DEFAULT_SL_REGION_BUFFER_IN_KM } from 'hooks/useRoadCoverage';

const color = 'rgba(0, 141, 141, 1)';
const externalRingColor = 'rgba(255, 93, 0, 1)';
const layerByFrc = {
  0: [
    {
      id: 'frc0',
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['==', ['get', 'type'], 'EXPANDED'],
          externalRingColor,
          color,
        ],
        'line-width': {
          stops: [
            [4, 1],
            [5, 1],
            [6, 1],
            [7, 4],
            [8, 5],
            [10, 5],
            [11, 5],
            [12, 6],
            [13, 7],
            [14, 10],
            [15, 12],
            [16, 14.5],
            [17, 18],
            [18, 20],
          ],
        },
      },
      layout: {
        'line-cap': 'round',
      },
    },
  ],
  1: [
    {
      id: 'frc1',
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['==', ['get', 'type'], 'EXPANDED'],
          externalRingColor,
          color,
        ],
        'line-width': {
          stops: [
            [4, 1],
            [5, 1],
            [6, 1],
            [8, 3],
            [10, 3.5],
            [12, 4],
            [13, 5],
            [14, 6],
            [15, 11],
            [16, 15],
            [17, 17],
            [18, 19],
          ],
        },
      },
      layout: {
        'line-cap': 'round',
      },
    },
  ],
  2: [
    {
      id: 'frc2',
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['==', ['get', 'type'], 'EXPANDED'],
          externalRingColor,
          color,
        ],
        'line-width': {
          stops: [
            [4, 1],
            [5, 1],
            [6, 1],
            [7, 3],
            [8, 3],
            [10, 3.5],
            [12, 4],
            [13, 5],
            [14, 6],
            [15, 10],
            [16, 12],
            [17, 16],
            [18, 18],
          ],
        },
      },
      layout: {
        'line-cap': 'round',
      },
    },
  ],
  3: [
    {
      id: 'frc3',
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['==', ['get', 'type'], 'EXPANDED'],
          externalRingColor,
          color,
        ],
        'line-width': {
          stops: [
            [6, 0],
            [7, 0],
            [8, 0],
            [9, 0],
            [10, 3],
            [11, 3],
            [12, 3],
            [13, 6],
            [14, 7],
            [15, 11],
            [16, 13],
            [17, 15],
            [18, 17],
          ],
        },
      },
      layout: {
        'line-cap': 'round',
      },
    },
  ],
  4: [
    {
      id: 'frc4',
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['==', ['get', 'type'], 'EXPANDED'],
          externalRingColor,
          color,
        ],
        'line-width': {
          stops: [
            [6, 0],
            [7, 0],
            [8, 0],
            [9, 0],
            [10, 0],
            [11, 3],
            [12, 3],
            [13, 3.5],
            [14, 6],
            [15, 8],
            [16, 15],
            [17, 24],
            [18, 25],
          ],
        },
      },
      layout: {
        'line-cap': 'round',
      },
    },
  ],
  5: [
    {
      id: 'frc5',
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['==', ['get', 'type'], 'EXPANDED'],
          externalRingColor,
          color,
        ],
        'line-width': {
          stops: [
            [10, 1],
            [11, 1.5],
            [12, 2],
            [13, 2.5],
            [14, 4],
            [15, 6],
            [16, 10],
            [17, 14],
            [18, 16],
          ],
        },
      },
      layout: {
        'line-cap': 'round',
      },
    },
  ],
  6: [
    {
      id: 'frc6',
      type: 'line',
      paint: {
        'line-color': [
          'case',
          ['==', ['get', 'type'], 'EXPANDED'],
          externalRingColor,
          color,
        ],
        'line-width': {
          stops: [
            [10, 1],
            [13, 2],
            [14, 2],
            [15, 4],
            [16, 9],
            [17, 13],
            [18, 15],
          ],
        },
      },
      layout: {
        'line-cap': 'round',
      },
    },
  ],
};

interface RoadSegmentsProps {
  regions: RegionDto[];
}

function RoadSegments(props: RoadSegmentsProps): null {
  const [menu] = useMenu();

  const mapType =
    menu.type === AnalysisType.SelectedLink ? menu.mapType : undefined;
  const mapVersion =
    menu.type === AnalysisType.SelectedLink ? menu.mapVersion : undefined;

  const regions = props.regions;
  const [dataPerFrc, setDataPerFrc] = useState({
    0: { type: 'FeatureCollection', features: [] },
    1: { type: 'FeatureCollection', features: [] },
    2: { type: 'FeatureCollection', features: [] },
    3: { type: 'FeatureCollection', features: [] },
    4: { type: 'FeatureCollection', features: [] },
    5: { type: 'FeatureCollection', features: [] },
    6: { type: 'FeatureCollection', features: [] },
  });

  const map = useMap();

  useEffect(() => {
    const newLocal = throttle(
      () => {
        drawSegments(regions);
      },
      500,
      { trailing: true, leading: false },
    );
    map.on('move', newLocal);

    return () => {
      map.off('move', newLocal);
    };
  }, [map, regions]);

  useEffect(() => {
    drawSegments(regions);
  }, [regions]);

  const drawSegments = (regions: RegionDto[]) => {
    let isValid = true;
    async function fetchSegments() {
      const segments = await getSegments(regions);
      if (isValid) {
        const segmentsPerFrc: any = {
          0: { type: 'FeatureCollection', features: [] },
          1: { type: 'FeatureCollection', features: [] },
          2: { type: 'FeatureCollection', features: [] },
          3: { type: 'FeatureCollection', features: [] },
          4: { type: 'FeatureCollection', features: [] },
          5: { type: 'FeatureCollection', features: [] },
          6: { type: 'FeatureCollection', features: [] },
        };
        segments.forEach(([coords, frc, type]: any) => {
          segmentsPerFrc[frc].features.push({
            type: 'Feature',
            properties: { type: type },
            geometry: { type: 'LineString', coordinates: coords },
          });
        });
        setDataPerFrc(segmentsPerFrc);
      }
    }
    fetchSegments();
    return () => {
      isValid = false;
    };
  };

  const getSegments = async (regions: RegionDto[]) => {
    const boundingBox =
      map.getBounds().getWest() +
      ',' +
      map.getBounds().getSouth() +
      ',' +
      map.getBounds().getEast() +
      ',' +
      map.getBounds().getNorth();

    const correctBufferRadiusInKilometers = isSelectedLinkRegionSelected(menu)
      ? DEFAULT_SL_REGION_BUFFER_IN_KM
      : menu.bufferRadiusInKilometers;

    return await RoadCoverageApi.getSegments(
      regions,
      boundingBox,
      map.getZoom(),
      mapType,
      mapVersion,
      correctBufferRadiusInKilometers,
    );
  };

  Object.keys(layerByFrc).forEach((frc) => {
    const layer = layerByFrc[frc];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayers(layer[0].id, layer, dataPerFrc[frc], 'Non public road label');
  });

  return null;
}

export default RoadSegments;
