import { SelectedLinkRequestPage } from 'components/RequestPages/SelectedLinkRequestPage';
import { Loading } from 'components/UI/Loading';
import Loadable from 'react-loadable';
import { Redirect, Route, Switch } from 'react-router-dom';
import SupportPage from '../SupportPage/SupportPage';
import ErrorBoundary from './ErrorBoundary';
import { LoggedInOnly } from './LoggedInOnly';
import NavBar from './NavBar/NavBar';
import ShareNavBar from './ShareNavBar';
import { SupportOnly } from './SupportOnly';

const PagePreloader = (props: { pastDelay: boolean }) =>
  props.pastDelay ? (
    <div
      style={{
        height: 'calc(100vh - 300px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loading />
    </div>
  ) : null;

export const MapFlowsPro = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "mfp"  */ '../AnalysisViewPage/MapFlowsPro/MapFlowsPro'
    ),
  loading: PagePreloader,
});

const NewPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "new"  */ '../AnalysisNew/AnalysisNew'),
  loading: PagePreloader,
});

const RegionsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "regions"  */ '../AnalysisNew/RegionSelection/RegionSelection'
    ),
  loading: PagePreloader,
});

const ViewPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "view"  */ '../AnalysisViewPage/AnalysisViewPage'
    ),
  loading: PagePreloader,
});

const Dashboard = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboard"  */ '../AnalysisTable/AnalysisTable'
    ),
  loading: PagePreloader,
});

const ReleaseNotes = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "release-notes"  */ '../ReleaseNotes/ReleaseNotes'
    ),
  loading: PagePreloader,
});

// eslint-disable-next-line react/display-name
const support = (C: any) => (props: any) => (
  <SupportOnly>
    <C {...props} />
  </SupportOnly>
);

const ShareViewPage = (props: any) => (
  <>
    <ShareNavBar />
    <ViewPage {...props} />
  </>
);

const BasePage = () => (
  <ErrorBoundary>
    <Switch>
      <Route
        path="/share/selected-link/:analysisId"
        component={ShareViewPage}
      />
      <Route path="/share/:analysisId" component={ShareViewPage} />
      <LoggedInOnly>
        <Route component={NavBar} />
        <Switch>
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/dashboard/drafts" exact component={Dashboard} />
          <Route path="/new" component={NewPage} />
          <Route path="/new/regions" exact component={RegionsPage} />
          <Route path="/view/selected-link/:analysisId" component={ViewPage} />
          <Route path="/view/:analysisId" component={ViewPage} />
          <Route path="/release-notes" exact component={ReleaseNotes} />
          <Route path="/support" component={support(SupportPage)} />
          <Route
            path="/request/selected-link"
            component={SelectedLinkRequestPage}
          />
          <Redirect to="/dashboard" />
        </Switch>
      </LoggedInOnly>
    </Switch>
  </ErrorBoundary>
);

export default BasePage;
