import { Feature, featureCollection, LineString, point } from '@turf/turf';
import { useMemo } from 'react';
import { Circles, Lines, Source } from './MapboxComponents';

export const allSegmentsLayers = {
  allSegments: 'all-segments',
  segmentsEnd: 'segments-end',
};

export function MapSegmentsLayer({
  segments,
  beforeId,
}: {
  segments?: Feature<LineString>[];
  beforeId?: string;
}) {
  const segmentEnds = useMemo(
    () =>
      segments === undefined
        ? undefined
        : featureCollection(
            segments.flatMap((it) => {
              const coords = it.geometry.coordinates;
              return [point(coords[0]), point(coords[coords.length - 1])];
            }),
          ),
    [segments],
  );

  return (
    <>
      <Source id={allSegmentsLayers.allSegments} data={segments}>
        <Lines
          beforeId={beforeId}
          id="all-segments"
          line-color="rgba(190, 190, 190, 1)"
          line-width={1}
        />
      </Source>
      <Source id={allSegmentsLayers.segmentsEnd} data={segmentEnds}>
        <Circles
          beforeId={beforeId}
          id={allSegmentsLayers.segmentsEnd}
          circle-color="rgba(255, 255, 255, 1)"
          circle-stroke-color="rgba(190, 190, 190, 1)"
          circle-stroke-width={{
            stops: [
              [13, 0],
              [13.5, 1],
            ],
          }}
          circle-radius={{
            stops: [
              [13, 0],
              [14, 2],
              [19, 4],
              [22, 5],
            ],
          }}
        />
      </Source>
    </>
  );
}
