import { Feature } from '@turf/turf';
import { SegmentsApi } from 'api/SegmentsApi';
import { ValidationStatus } from 'components/AnalysisNew/RegionSelection/validation';
import { RegionDto } from 'model/RegionDto';
import { useEffect, useState } from 'react';
import { useMenu } from 'reducers/menuReducer';

interface Props {
  region: RegionDto;
}

export const useSegmentsIntersectRegion = ({ region }: Props) => {
  const [intersectionPoints, setIntersectionPoints] = useState<Feature[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [menu] = useMenu();

  useEffect(() => {
    const fetchData = async () => {
      if (
        region?.properties?.validationResult?.status === ValidationStatus.OK &&
        menu.entrancesFrcs?.length > 0
      ) {
        setError(undefined);
        setLoading(true);
        try {
          const intersectionsPoints = await SegmentsApi.getSegmentsIntersectRegion(
            region,
            menu.mapVersion,
            menu.mapType,
            menu.entrancesFrcs,
          );

          setIntersectionPoints(intersectionsPoints);
        } catch (e) {
          setError(e);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [region, menu.mapVersion, menu.mapType, menu.entrancesFrcs]);

  return {
    intersectionPoints,
    loading,
    error,
  };
};
