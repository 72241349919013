import { useRoadCoverage } from 'hooks/useRoadCoverage';
import { AnalysisType } from 'model/AnalysisDto';
import { RegionDto } from 'model/RegionDto';
import { useMenu } from 'reducers/menuReducer';
import { SpinnerIcon } from 'tombac-icons';
import { Kilometers } from 'components/UI/Kilometers/Kilometers';
import { isSelectedLinkRegionSelected } from 'components/SelectedLinkPage/utils';

const RoadCoverageInfo = ({
  regions,
  type,
  analysisId,
}: {
  regions?: RegionDto[];
  type: AnalysisType;
  analysisId?: number;
}) => {
  const [menu] = useMenu();

  const menuRegions =
    menu.type === AnalysisType.FlowMatrix || isSelectedLinkRegionSelected(menu)
      ? menu.regions
      : menu.links;

  const { coverage, loading: coverageLoading, error } = useRoadCoverage({
    regions: regions ?? menuRegions,
    type: type ?? menu.type,
    mapType: menu.mapType,
    mapVersion: menu.mapVersion,
    analysisId,
    bufferRadiusInKilometers: menu.bufferRadiusInKilometers,
  });

  return (
    <span>
      {(menu.regions.length > 0 && coverageLoading) ||
      coverage === undefined ? (
        <SpinnerIcon spin />
      ) : error ? (
        error.message
      ) : (
        <Kilometers squared={false} value={coverage ?? 0} />
      )}
    </span>
  );
};

export default RoadCoverageInfo;
