import ResultSpinner from 'components/UI/ResultSpinner/ResultSpinner';
import {
  formatDateRange,
  formatTimeRange,
} from 'logic/analysis/dimensionUtils';
import { ReadableResult } from 'logic/ReadableResult';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, FormGroup, Text } from 'tombac';
import { RegionDto } from '../../../model/RegionDto';
import GlMap from '../../Map/GlMap';
import MapScreenshot from '../../Map/MapScreenshot';
import { centerOnRegions } from '../../Map/mapUtils';
import DateTimeSelector from '../../UI/DateTimeSelector/DateTimeSelector';
import { MapControls, ZoomInButton } from '../../UI/UI';
import { useAnalysisContext } from '../AnalysisViewPage';
import { calculateArea } from '../MapFlows/calculateArea';
import syncMaps, { copyMapPosition } from '../MapFlows/sync-move';
import { PartialResult } from '../MapFlowsPro/logic/PartialResult';
import { useScenario } from '../MapFlowsPro/logic/scenario';
import ViewPageNavbar from '../NavBar/ViewPageNavbar';
import ViewPageContent from '../ViewPageContent/ViewPageContent';
import './SpatialSankey.css';
import SpatialSankeyGl, { Strand } from './SpatialSankeyGl';
import { StrandPopup } from './StrandPopup';

const meters2tokm2 = (value: number) => value / 1000000;
const makeStrands = (
  result: ReadableResult | undefined,
  carsPerKm: boolean,
  regions: RegionDto[],
  regionAreas: number[],
): Strand[] => {
  if (result === undefined) {
    return undefined;
  }
  const size = regions.length;
  const flows = [];
  for (let o = 0; o < size; o++) {
    for (let d = 0; d < o; d++) {
      const trips = result.get(o, d).trips + result.get(d, o).trips;
      let divisor = 1;
      if (carsPerKm) {
        divisor = regionAreas[o] + regionAreas[d];
      }
      if (trips > 0) {
        flows.push({
          source: o,
          target: d,
          flow: trips / divisor,
        });
      }
    }
  }
  return flows;
};

function SpatialSankey() {
  const { analysis } = useAnalysisContext();
  const [filter, setFilter] = useState(5);
  const [carsPerKm, setCarsPerKm] = useState(false);
  const [regionNames, setRegionNames] = useState(true);
  const mainMapRef = useRef<mapboxgl.Map>(undefined);
  const compareMapRef = useRef<mapboxgl.Map>(undefined);
  const [selectedStrand, setSelectedStrand] = useState<Strand | undefined>(
    undefined,
  );

  const regionAreas = useMemo(
    () => analysis.regions.map(calculateArea).map(meters2tokm2),
    [analysis.regions],
  );

  // Center map on regions effect
  useEffect(() => {
    if (mainMapRef.current === undefined) {
      return;
    }
    centerOnRegions(mainMapRef.current, analysis.regions);
  }, [analysis.regions]);

  const { scenario, setScenario, scenarioActions } = useScenario();
  const { result, compareResult, loading } = PartialResult.use(
    analysis,
    scenario,
  );
  useEffect(() => {
    const allRegionIds = analysis.regions.map((_, i) => i);
    scenarioActions.setOrigins(allRegionIds);
    scenarioActions.setDestinations(allRegionIds);
  }, []);

  const compare = scenario.dateRangeCompare !== undefined;

  // Sync/unsynch maps effect
  useEffect(() => {
    if (!compare || !compareMapRef.current) {
      return;
    }

    let off: any;
    const syncViews = () => {
      window.dispatchEvent(new Event('resize'));
      copyMapPosition(mainMapRef.current, compareMapRef.current);
      off = syncMaps(mainMapRef.current, compareMapRef.current).off;
    };
    syncViews();
    return () => {
      if (off) {
        off();
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 0);
      }
    };
  }, [compare, compareResult]);

  const mainStrands = useMemo(
    () => makeStrands(result, carsPerKm, analysis.regions, regionAreas),
    [result, carsPerKm, analysis.regions, regionAreas],
  );

  const compareStrands = useMemo(
    () =>
      makeStrands(
        scenario.dateRangeCompare !== undefined ? compareResult : undefined,
        carsPerKm,
        analysis.regions,
        regionAreas,
      ),
    [compareResult, carsPerKm, analysis.regions, regionAreas],
  );

  return (
    <>
      <ViewPageNavbar visualisationName="Spatial Sankey" />
      <ViewPageContent>
        {loading && <ResultSpinner subtitle="Loading results..." />}
        <DateTimeSelector
          map
          comparable
          scenario={scenario}
          onChange={setScenario}
          analysis={analysis}
        />
        <div style={{ display: 'flex', width: compare ? '50%' : '100%' }}>
          <GlMap
            mapboxRef={(c) => (mainMapRef.current = c)}
            mapStyle="basic_mono"
            controlLocation="bottom-left"
          >
            {analysis.regions.length > 0 && (
              <ZoomInButton
                onClick={() =>
                  centerOnRegions(mainMapRef.current, analysis.regions)
                }
                position="bottom-left"
                type="regions"
              />
            )}
            {mainStrands && (
              <>
                {selectedStrand && (
                  <StrandPopup
                    result={result}
                    strand={selectedStrand}
                    regions={analysis.regions}
                    onClose={() => setSelectedStrand(undefined)}
                  />
                )}
                <SpatialSankeyGl
                  flows={mainStrands}
                  dtoRegions={analysis.regions}
                  regionNames={regionNames}
                  filter={filter}
                  carsPerKm={carsPerKm}
                  selectedStrand={selectedStrand}
                  onStrandSelect={setSelectedStrand}
                  period={
                    '' +
                    formatDateRange(result.meta().dateRange) +
                    formatTimeRange(result.meta().timeRange)
                  }
                />
                {!compare && (
                  <MapControls title="Controls">
                    <Checkbox
                      checked={carsPerKm}
                      label="Flows/km²"
                      onChange={(e) => setCarsPerKm(e.target.checked)}
                    />
                    <Checkbox
                      checked={regionNames}
                      label="Region names"
                      onChange={(e) => setRegionNames(e.target.checked)}
                    />
                    <MapScreenshot $m="1sp 0" map={mainMapRef.current} />
                    <FormGroup label="Lower limit">
                      <Text>{filter}%</Text>
                      <input
                        type="range"
                        min={0}
                        max={100}
                        value={filter}
                        onChange={(e) => setFilter(Number(e.target.value))}
                      />
                    </FormGroup>
                  </MapControls>
                )}
              </>
            )}
          </GlMap>
        </div>
        {compare && compareStrands !== undefined && (
          <div style={{ display: 'flex', width: '50%' }}>
            <GlMap
              mapboxRef={(c) => (compareMapRef.current = c)}
              mapStyle="basic_mono"
              controlLocation="bottom-left"
              independent
            >
              <MapControls title="Controls">
                <Checkbox
                  checked={carsPerKm}
                  label="Flows/km²"
                  onChange={(e) => setCarsPerKm(e.target.checked)}
                />
                <Checkbox
                  checked={regionNames}
                  label="Region names"
                  onChange={(e) => setRegionNames(e.target.checked)}
                />

                <FormGroup label="Lower limit">
                  <Text>{filter}%</Text>
                  <input
                    type="range"
                    min={0}
                    max={100}
                    value={filter}
                    onChange={(e) => setFilter(Number(e.target.value))}
                  />
                </FormGroup>
              </MapControls>
              {selectedStrand && (
                <StrandPopup
                  result={compareResult}
                  strand={selectedStrand}
                  regions={analysis.regions}
                  onClose={() => setSelectedStrand(undefined)}
                />
              )}
              <SpatialSankeyGl
                selectedStrand={selectedStrand}
                onStrandSelect={setSelectedStrand}
                flows={compareStrands}
                dtoRegions={analysis.regions}
                regionNames={regionNames}
                filter={filter}
                carsPerKm={carsPerKm}
                period={
                  '' +
                  formatDateRange(compareResult.meta().dateRange) +
                  formatTimeRange(compareResult.meta().timeRange)
                }
              />
            </GlMap>
          </div>
        )}
      </ViewPageContent>
    </>
  );
}

export default SpatialSankey;
