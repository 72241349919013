import { point, polygon } from '@turf/helpers';
import { booleanPointInPolygon } from '@turf/turf';
import mapboxgl, { Control, LngLatBoundsLike } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import './GlMap.css';
// import maptilerStyle from './maptilerStyle';
import empty from './styles/empty.json';

// Add support for RTL text
if (mapboxgl.getRTLTextPluginStatus() === 'unavailable') {
  mapboxgl.setRTLTextPlugin(
    'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.0/mapbox-gl-rtl-text.js',
    () => {},
  );
}

export type MapStyleName = 'basic_main' | 'basic_mono';

const supportedLanguages = [
  'NGT',
  'NGT-Latn',
  'ar',
  'bg-BG',
  'zh-TW',
  'zh-CN',
  'cs-CZ',
  'da-DK',
  'nl-NL',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-NZ',
  'en-US',
  'fi-FI',
  'fr-FR',
  'de-DE',
  'el-GR',
  'hu-HU',
  'id-ID',
  'it-IT',
  'ko-KR',
  'lt-LT',
  'ms-MY',
  'nb-NO',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ru-RU',
  'ru-Latn-RU',
  'ru-Cyrl-RU',
  'sk-SK',
  'sl-SL',
  'es-ES',
  'es-MX',
  'sv-SE',
  'th-TH',
  'tr-TR',
];
const addLanguage = (style: mapboxgl.Style) => {
  const tiles = (style.sources.vectorTiles as any).tiles;
  const usersLanguage = (
    navigator.languages ?? [navigator.language]
  ).find((l) => supportedLanguages.includes(l));
  const language = usersLanguage ?? 'en-US';
  const tilesWithLanguage = tiles.map(
    (url: string) => url + '&language=' + language,
  );
  (style.sources.vectorTiles as any).tiles = tilesWithLanguage;
};

const styleCache = {};
const fetchStyle = async (style: string) => {
  if (styleCache[style]) return styleCache[style];
  const response = await fetch(
    'https://d3m5zl3ljbpwma.cloudfront.net/styles/' + style + '.json',
  );
  const json = await response.json();
  addLanguage(json);
  styleCache[style] = json;
  return json;
};

const getStyle = (mapStyle: MapStyleName) => {
  switch (mapStyle) {
    case 'basic_main':
      return fetchStyle('basic_main_japan');
    case 'basic_mono':
      return fetchStyle('basic_mono_japan');
  }
};

const updateStyle = async (map: mapboxgl.Map, mapStyle: MapStyleName) => {
  const newStyle = await getStyle(mapStyle);

  map.setStyle(newStyle);
};

type ControlLocation =
  | 'top-right'
  | 'top-left'
  | 'bottom-right'
  | 'bottom-left';

interface MapProps {
  independent?: boolean;
  mapboxRef?: (c: mapboxgl.Map) => void;
  children?: React.ReactNode;
  controlLocation?: ControlLocation;
  nonInteractive?: boolean;
  mapStyle?: MapStyleName;
  maxZoom?: number;
  disableScroll?: boolean;
  dark?: boolean;
  center?: any;
  zoom?: number;
  pitch?: number;
  bounds?: LngLatBoundsLike;
}

export const MapContext = React.createContext(null);

const createMapInstance = () =>
  new mapboxgl.Map({
    container: document.createElement('div'),
    style: empty as any,
    center: [-9.445531804936309, 35.4606814723237],
    zoom: 1.5,
    minZoom: 1,
    maxZoom: 20,
    preserveDrawingBuffer: true,
  });

interface MapInfo {
  instance: mapboxgl.Map;
  currentStyle: MapStyleName | undefined;
  controlLocation?: ControlLocation;
  control?: Control;
}
const globalMap: MapInfo = {
  instance: createMapInstance(),
  currentStyle: undefined as any,
};

const currentYear = new Date().getFullYear();
function GlMap({
  children,
  mapStyle,
  disableScroll = false,
  mapboxRef,
  independent = false,
  controlLocation = 'top-left',
}: MapProps) {
  const [map] = useState<MapInfo>(() =>
    independent
      ? { instance: createMapInstance(), currentStyle: undefined }
      : globalMap,
  );
  const [loaded, setLoaded] = useState(false);
  const containerRef = useRef<any>();

  if (mapboxRef) {
    mapboxRef(map.instance);
  }

  const initJapanFilter = () => {
    const japanPolygon = polygon([
      [
        [123.82335072878072, 22.870897580462014],
        [122.78200591353999, 25.393899882518596],
        [127.34141836401284, 30.533053302288934],
        [127.42115532304064, 33.29237284171087],
        [128.19690217899915, 33.73046218111152],
        [130.3950945463655, 35.83820754777882],
        [137.20118251643692, 41.47464157220597],
        [141.06954532867246, 45.83404598993661],
        [151.52650238931017, 46.64250634326723],
        [142.89338881166321, 24.00474948920443],
        [123.82335072878072, 22.870897580462014],
      ],
    ]);

    function lngFromMercatorX(x: number) {
      return x * 360 - 180;
    }

    function latFromMercatorY(y: number) {
      const y2 = 180 - y * 360;
      return (360 / Math.PI) * Math.atan(Math.exp((y2 * Math.PI) / 180)) - 90;
    }

    function tileInJapan(tileId: any): boolean {
      const { x, y, z } = tileId.canonical;

      if (z < 7) return false;

      const worldSize = Math.pow(2, z);

      // Coordinate of the center of the tile
      const lng = lngFromMercatorX((x + 0.5) / worldSize);
      const lat = latFromMercatorY((y + 0.5) / worldSize);

      return booleanPointInPolygon(point([lng, lat]), japanPolygon);
    }

    function setTileFilter(sourceName: string, fn: (tile: any) => boolean) {
      const source = map.instance.getSource(sourceName);

      if (source) {
        (source as any).hasTile = fn;
      }
    }

    setTileFilter('openmaptiles', (tile) => tileInJapan(tile));
    setTileFilter('vectorTiles', (tile) => !tileInJapan(tile));
  };

  useEffect(() => {
    const create = async () => {
      setLoaded(false);
      let current = true;

      // Mount map to the DOM element
      if (containerRef.current !== undefined) {
        containerRef.current.appendChild(map.instance.getContainer());
        window.dispatchEvent(new Event('resize'));
      }

      // Add controls
      if (controlLocation !== map.controlLocation) {
        if (map.control) {
          map.instance.removeControl(map.control);
        }
        map.control = new mapboxgl.NavigationControl();
        map.controlLocation = controlLocation;
        map.instance.addControl(map.control, controlLocation);
      }

      // Update style if needed
      if (map.currentStyle !== mapStyle) {
        await updateStyle(map.instance, mapStyle);
        map.currentStyle = mapStyle;
      }

      // Init japan filtering after source was created but before map is loaded
      // to prevent blink after load
      map.instance.on('data', (e) => {
        if (
          e.dataType === 'source' &&
          e.sourceDataType === 'content' &&
          e.sourceId === 'openmaptiles'
        ) {
          initJapanFilter();
        }
      });

      const ready = () => {
        if (!current) {
          return;
        }
        setLoaded(true);
      };

      if (map.instance.isStyleLoaded()) {
        ready();
      } else {
        map.instance.once('styledata', () => {
          // There's a nasty bug in mapbox
          // delaying displaying content till the next frame helps
          setTimeout(() => {
            ready();
          }, 0);
        });
      }

      return () => {
        current = false;
      };
    };
    create();
  }, []);

  useEffect(() => {
    if (disableScroll) {
      map.instance.scrollZoom.disable();
    } else {
      map.instance.scrollZoom.enable();
    }
  }, [disableScroll]);

  return (
    <div ref={containerRef} className="GlMap">
      {loaded && (
        <MapContext.Provider value={map.instance}>
          {children}
        </MapContext.Provider>
      )}
      <div className="GlMap__attribution">
        <a href="https://www.tomtom.com/en_gb/thirdpartyproductterms/ ">
          © TomTom 1992 – {currentYear}
        </a>
      </div>
    </div>
  );
}

export default GlMap;
