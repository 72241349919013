import { Geometry } from '@turf/turf';
import MapScreenshot from 'components/Map/MapScreenshot';
import ResultSpinner from 'components/UI/ResultSpinner/ResultSpinner';
import { IconTooltip, MapControls, ZoomInButton } from 'components/UI/UI';
import Immutable from 'immutable';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import HelpCircle from 'react-feather/dist/icons/help-circle';
import { Box, Checkbox } from 'tombac';
import GlMap from '../../Map/GlMap';
import { centerOnRegions } from '../../Map/mapUtils';
import DateTimeSelector from '../../UI/DateTimeSelector/DateTimeSelector';
import { useAnalysisContext } from '../AnalysisViewPage';
import { PartialResult } from '../MapFlowsPro/logic/PartialResult';
import { useScenario } from '../MapFlowsPro/logic/scenario';
import ViewPageNavbar from '../NavBar/ViewPageNavbar';
import { mapboxBbox } from '../utils';
import ViewPageContent from '../ViewPageContent/ViewPageContent';
import './MapFlows.css';
import MapFlowsDetails from './MapFlowsDetails';
import MapFlowsLayer from './MapFlowsLayer';
import syncMove from './sync-move';

const copyMapPosition = (from: any, to: any) => {
  const center = from.getCenter();
  const zoom = from.getZoom();
  const bearing = from.getBearing();
  const pitch = from.getPitch();
  to.jumpTo({
    center,
    zoom,
    bearing,
    pitch,
  });
};

function Controls({
  selectedTo,
  selectedFrom,
  setLabels,
  labels,
  flowsPerKm,
  setFlowsPerKm,
  compare,
  mapbox,
}: {
  selectedTo: number;
  selectedFrom: number;
  setLabels: (b: boolean) => void;
  labels: boolean;
  flowsPerKm: boolean;
  setFlowsPerKm: (b: boolean) => void;
  compare: boolean;
  mapbox: mapboxgl.Map;
}) {
  const isVia = selectedFrom > -1 && selectedTo > -1;

  return (
    <MapControls title="Controls">
      <Box>
        <Checkbox
          label="Labels"
          checked={labels}
          onChange={(e) => setLabels(e.target.checked)}
        />
      </Box>
      <Box>
        {!isVia && (
          <Checkbox
            label={
              (
                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                  Flows/km²{' '}
                  <IconTooltip
                    icon={<HelpCircle size={18} />}
                    tooltip="Displays flows relative to the region area"
                  />
                </span>
              ) as any
            }
            checked={flowsPerKm}
            onChange={(e) => setFlowsPerKm(e.target.checked)}
          />
        )}
      </Box>
      {!compare && <MapScreenshot $mt="1sp" map={mapbox} />}
    </MapControls>
  );
}

function MapFlows() {
  const { analysis } = useAnalysisContext();

  const regions = analysis.regions;
  const hasExternals = analysis.info.passMatrix !== true;
  const mapboxRef = useRef<mapboxgl.Map>(undefined);
  const mapboxComparisonRef = useRef<mapboxgl.Map>(undefined);
  React.useEffect(() => {
    centerOnRegions(mapboxRef.current, regions, false);
  }, [regions]);

  const [selectedFrom, setSelectedFrom] = useState(-1);
  const [selectedTo, setSelectedTo] = useState(-1);
  const [labels, setLabels] = useState(true);

  const allRegions = useMemo(() => {
    const regionIds = analysis.regions.map((_, i) => i);
    return hasExternals ? [...regionIds, analysis.regions.length] : regionIds;
  }, [analysis.regions, hasExternals]);

  const { scenario, setScenario } = useScenario();
  const scenarioWithRegions = useMemo(() => {
    const isVia = selectedTo !== -1 && selectedFrom !== -1;
    const origins = isVia ? [selectedFrom] : allRegions;
    const destinations = isVia ? [selectedTo] : allRegions;
    const vias = isVia ? allRegions : [];
    return {
      ...scenario,
      origins: Immutable.Set(origins),
      destinations: Immutable.Set(destinations),
      vias: Immutable.Set(vias),
    };
  }, [scenario, selectedTo, selectedFrom]);

  const { result, compareResult, loading } = PartialResult.use(
    analysis,
    scenarioWithRegions,
  );

  const [hovered, setHovered] = useState(-1);
  const [flowsPerKm, setFlowsPerKm] = useState(false);

  const compare = scenario.dateRangeCompare !== undefined;

  const zoomToRegion = (geometry: Geometry) => {
    const map = mapboxRef.current;
    if (map) {
      map.fitBounds(mapboxBbox(geometry), { padding: 100 });
    }
  };

  useEffect(() => {
    if (!compare || !mapboxComparisonRef.current) {
      return;
    }

    let off: any;
    const syncViews = () => {
      window.dispatchEvent(new Event('resize'));
      copyMapPosition(mapboxRef.current, mapboxComparisonRef.current);
      off = syncMove(mapboxRef.current, mapboxComparisonRef.current).off;
    };
    syncViews();
    return () => {
      if (off) {
        off();
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 0);
      }
    };
  }, [compare, compareResult]);

  useEffect(() => {
    setHovered(undefined);
  }, [selectedFrom, selectedTo]);

  return (
    <>
      <ViewPageNavbar visualisationName="Map Flows" />
      <ViewPageContent>
        <>
          {loading && <ResultSpinner subtitle="Loading results..." map />}
          <DateTimeSelector
            scenario={scenario}
            onChange={setScenario}
            analysis={analysis}
            map
            comparable
          />
          <div
            style={{
              display: 'flex',
              width: scenario.dateRangeCompare !== undefined ? '50%' : '100%',
            }}
          >
            <GlMap
              mapboxRef={(c) => (mapboxRef.current = c)}
              mapStyle="basic_mono"
              controlLocation="bottom-left"
            >
              {analysis.regions.length > 0 && (
                <ZoomInButton
                  onClick={() =>
                    centerOnRegions(mapboxRef.current, analysis.regions)
                  }
                  position="bottom-left"
                  type="regions"
                />
              )}
              {result && (
                <>
                  <MapFlowsLayer
                    selectedFrom={selectedFrom}
                    selectedTo={selectedTo}
                    regions={regions}
                    result={result}
                    labels={labels}
                    flowsPerKm={flowsPerKm}
                    hovered={hovered}
                    onHover={setHovered}
                    hideLegend={compare}
                    setFromId={setSelectedFrom}
                    setToId={setSelectedTo}
                  />
                  <MapFlowsDetails
                    selectedFrom={selectedFrom}
                    selectedTo={selectedTo}
                    result={result}
                    dtoRegions={regions}
                    hovered={hovered}
                    onHover={setHovered}
                    onOrigin={setSelectedFrom}
                    onDestination={setSelectedTo}
                    hasExternals={hasExternals}
                    hideable={compare}
                    zoomToRegion={zoomToRegion}
                  />
                </>
              )}
              {!compare && (
                <Controls
                  selectedTo={selectedTo}
                  selectedFrom={selectedFrom}
                  labels={labels}
                  setLabels={setLabels}
                  flowsPerKm={flowsPerKm}
                  setFlowsPerKm={setFlowsPerKm}
                  compare={compare}
                  mapbox={mapboxRef.current}
                />
              )}
            </GlMap>
          </div>
          {compareResult && (
            <div style={{ display: 'flex', width: compare ? '50%' : '100%' }}>
              <GlMap
                mapboxRef={(c) => (mapboxComparisonRef.current = c)}
                mapStyle="basic_mono"
                controlLocation="bottom-left"
                independent
              >
                {compareResult && (
                  <>
                    <MapFlowsLayer
                      selectedFrom={selectedFrom}
                      selectedTo={selectedTo}
                      regions={regions}
                      result={compareResult}
                      labels={labels}
                      flowsPerKm={flowsPerKm}
                      hovered={hovered}
                      onHover={setHovered}
                      setFromId={setSelectedFrom}
                      setToId={setSelectedTo}
                    />
                  </>
                )}
                {compare && (
                  <Controls
                    selectedTo={selectedTo}
                    selectedFrom={selectedFrom}
                    labels={labels}
                    setLabels={setLabels}
                    flowsPerKm={flowsPerKm}
                    setFlowsPerKm={setFlowsPerKm}
                    compare={compare}
                    mapbox={mapboxRef.current}
                  />
                )}
              </GlMap>
            </div>
          )}
        </>
      </ViewPageContent>
    </>
  );
}

export default MapFlows;
