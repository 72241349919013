import { saveAs } from 'file-saver';
import useMap from 'hooks/useMap';
import { useState } from 'react';
import styled from 'styled-components';
import { Button, TooltipOnHover, tombac } from 'tombac';
import { CameraIcon } from 'tombac-icons';

const Container = styled.div`
  position: absolute;
  bottom: 136px;
  right: 0;
  margin: ${tombac.space(2)} ${tombac.space(1)};
  z-index: 10;
  box-shadow: 0px 3px 8px rgb(0 0 0 / 10%);
  border-radius: 20px;
`;

const TakeScreenshotButton = styled(Button)`
  border-radius: 20px;
`;

export function MapScreenshotButton({ filename }: { filename?: string }) {
  const [loading, setLoading] = useState(false);
  const map = useMap();

  function takeScreenshot() {
    setLoading(true);
    const canvas = map.getCanvas();

    canvas.toBlob(
      (blob) => {
        saveAs(blob, (filename ?? 'map') + '.jpg');
        setLoading(false);
      },
      'image/jpeg',
      1,
    );
  }

  return (
    <Container>
      <TooltipOnHover content="Take a screenshot" variant="inverted" size="s">
        <TakeScreenshotButton
          busy={loading}
          shape="circle"
          onClick={takeScreenshot}
          variant="flat"
          theme={{
            backgroundColor: '--white',
            hover: { backgroundColor: '--neutral-300', color: '--black' },
          }}
        >
          <CameraIcon />
        </TakeScreenshotButton>
      </TooltipOnHover>
    </Container>
  );
}
