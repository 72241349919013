import { getStore } from 'index';
import { appConfig } from 'logic/appConfig';
import { appInsights } from 'logic/tracking';

const apiUrl = appConfig.apiUrl;

export const apiKey = () => {
  return getStore()?.user?.apiKey;
};

type ParseAs = 'text' | 'json' | 'blob' | 'none';

export async function fetchApi<T>(
  url: string,
  params: Omit<RequestInit, 'credentials'> = {},
  parseAs?: ParseAs,
): Promise<T> {
  let fullUrl = url.includes('http') ? url : `${apiUrl}${url}`;
  const key = apiKey();
  if (key) {
    if (fullUrl[fullUrl.length - 1] === '&') {
      fullUrl += 'key=' + key;
    } else if (fullUrl.includes('?')) {
      fullUrl += `&key=` + key;
    } else {
      fullUrl += `?key=` + key;
    }
  }

  let response;
  try {
    response = await fetch(fullUrl, {
      ...params,
      credentials: 'include',
    });
  } catch (e) {
    appInsights.trackException({ exception: e });
    throw e;
  }

  let body;
  if (!parseAs && params.method !== 'POST' && params.method !== 'DELETE') {
    parseAs = 'json';
  }
  if (parseAs === 'blob') {
    body = await response.blob();
  }
  if (parseAs === 'json') {
    body = await response.json();
  }
  if (parseAs === 'text') {
    body = await response.text();
  }

  if (!response.ok) {
    if (!body) {
      try {
        body = await response.json();
      } catch (e) {}
    }

    // While error logging is handled by ErrorBoundary
    // it is also send from here in case this error is not handled
    // so we might have two logs for one error which is still better than 0
    appInsights.trackException({ exception: new Error(JSON.stringify(body)) });

    if (!body) {
      try {
        body = await response.json();
      } catch (e) {}
    }

    if (body.message) {
      throw new Error(body.message);
    } else {
      throw new Error(JSON.stringify(body));
    }
  }

  return body;
}

export const makeApiLink = (url: string, params: Record<any, any>) => {
  const search = new URLSearchParams(params);
  search.set('key', apiKey());
  return apiUrl + url + '?' + search.toString();
};

export const authorizeUrl = (url: string) => {
  const link = new URL(url);

  if (apiKey() && !link.searchParams.has('t')) {
    link.searchParams.set('key', apiKey());
  }

  return link.toString();
};

export const retriable = async <ResultType>(
  maxRetries: number,
  fn: () => Promise<ResultType>,
) => {
  let attemptNumber = 0;
  while (attemptNumber < maxRetries) {
    attemptNumber++;
    try {
      const result = await fn();
      return result;
    } catch (e) {
      // Request failed, try again
    }
  }
  throw new Error(`Failed to perform request after ${attemptNumber} retries`);
};
