import { ReactNode, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Box,
  Button,
  defaultTheme,
  Heading,
  Input,
  propStyling,
  PropsWithPropStyling,
  tombac,
  Tooltip,
} from 'tombac';
import {
  AddIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  EditIcon,
  MenuKebabIcon,
  WarningIcon,
} from 'tombac-icons';
import emptySummary from '../../svg/empty-summary.svg';
import emptyPage from '../../svg/empty.svg';
import { SimpleTooltip } from '../AnalysisTable/SimpleTooltip';
import { Menu } from './Menu/Menu';

export const Text = styled.span<{
  display?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  marginTop?: string;
  fontWeight?: string;
  fontSize?: string;
  fontFamily?: string;
  lineHeight?: string;
  color?: string;
}>`
  display: ${(p) => p.display};
  margin-left: ${(p) => p.marginLeft};
  margin-right: ${(p) => p.marginRight};
  margin-top: ${(p) => p.marginTop};
  margin-bottom: ${(p) => p.marginBottom};
  font-family: ${(p) => p.fontFamily ?? tombac.fontFamily};
  font-size: ${(p) => p.fontSize ?? '12px'};
  font-weight: ${(p) => p.fontWeight ?? 'normal'};
  font-stretch: normal;
  font-style: normal;
  line-height: ${(p) => p.lineHeight ?? 'normal'};
  letter-spacing: normal;
  color: ${(p) => p.color ?? '#7a7e80'};
`;

export const BoldText = styled(Text)`
  font-weight: 500;
  color: #000000;
`;

export const Dot = () => (
  <span
    style={{
      margin: '6px 6px 12px 6px',
      color: '#bebebe',
    }}
  >
    •
  </span>
);

export const FormContainer = styled.div`
  background: #fff;
  display: flex;
  flex-grow: 1;
`;

export const Aside = styled.aside<{ overflow?: 'auto' | 'unset' | 'none' }>`
  width: 385px;
  border-right: 1px solid ${tombac.color('neutral', 400)};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow-y: ${(p) => p.overflow ?? 'auto'};
`;

export const FormContent = styled.div<{
  variant?: 'default' | 'dark' | 'gray';
}>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  background: ${(p) =>
    ({
      default: '#FFF',
      dark: '#f2f2f2',
      gray: '#fbfbfb',
    }[p.variant])};
`;

export const ContentCenter = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageEmpty = () => (
  <ContentCenter>
    <Flex direction="column" alignItems="center">
      <img src={emptyPage} />
    </Flex>
  </ContentCenter>
);

export const SummaryEmpty = () => (
  <ContentCenter>
    <Flex direction="column" alignItems="center">
      <img src={emptySummary} />
      <div
        style={{
          fontFamily: defaultTheme.typography.altFontFamily,
          fontSize: '20px',
          fontWeight: 500,
          marginBottom: '16px',
        }}
      >
        You almost made the report...!
      </div>
      <div
        style={{
          fontFamily: defaultTheme.typography.defaultFontFamily,
          fontSize: '16px',
          lineHeight: 1.56,
          color: 'var(--neutral-600)',
          textAlign: 'center',
        }}
      >
        Take a look at the added items and
        <br /> order the report
      </div>
    </Flex>
  </ContentCenter>
);

export function AsideNewButton({
  children,
  path = '#',
  onClick = () => {},
  disabled,
  ...rest
}: {
  path?: string;
  onClick?: () => any;
  disabled?: boolean;
  children?: any;
  'data-test'?: any;
}) {
  if (children === undefined) {
    return;
  }

  return (
    <Link to={disabled ? '#' : path}>
      <Button
        prepend={<AddIcon />}
        style={{ width: '100%' }}
        onClick={() => {
          if (!disabled) {
            onClick();
          }
        }}
        disabled={disabled}
        $mb="2sp"
        {...rest}
      >
        {children}
      </Button>
    </Link>
  );
}

export const AsideSection = styled.div<{
  gray?: boolean;
  direction?: 'column' | 'row';
  grow?: boolean;
  overflow?: string;
  disableBorder?: boolean;
}>`
  border-bottom: ${(p) => (p.disableBorder ? 'none' : '1px solid #e6e6e6')};
  display: flex;
  position: relative;
  padding: ${tombac.space(2)} ${tombac.space(3)};
  flex-direction: ${(p) => p.direction ?? 'column'};
  flex-grow: ${(p) => (p.grow ? '1' : 'unset')};
  background: ${(p) => (p.gray ? '#fbfbfb' : '#FFF')};
  overflow-y: ${(p) => p.overflow};

  :last-of-type {
    border-bottom: none;
  }
`;

const ExpandableAsideSectionHeader = styled(Button)<{ gray?: boolean }>`
  border-radius: 0;
  height: 56px;
  padding-right: 24px;
  padding-left: 24px;
  width: 100%;

  & span {
    width: 100%;
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 5px;
  }

  background: ${(p) => (p.gray ? '#fbfbfb' : '#FFF')};

  :hover {
    background: ${(p) => (p.gray ? '#fbfbfb' : '#FFF')};
  }
`;

const ExpandableAsideSectionTitle = styled.span`
  font-family: ${tombac.altFontFamily};
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const ExpandableAsideSectionBody = styled.div<{
  gray?: boolean;
  direction?: 'column' | 'row';
  grow?: boolean;
  overflow?: string;
}>`
  display: flex;
  position: relative;
  padding: ${tombac.space(2)} ${tombac.space(3)};
  flex-direction: ${(p) => p.direction ?? 'column'};
  flex-grow: ${(p) => (p.grow ? '1' : 'unset')};
  background: ${(p) => (p.gray ? '#fbfbfb' : '#FFF')};
  overflow-y: ${(p) => p.overflow};
`;

const ExpandableAsideSectionContainer = styled.div`
  border-bottom: 1px solid ${tombac.color('neutral', 400)};

  :last-of-type {
    border-bottom: none;
  }
`;

export const ExpandableAsideSection = ({
  expanded = true,
  children,
  title,
  gray,
  direction,
  grow,
  overflow,
}: {
  expanded?: boolean;
  title: any;
  children: any;
  className?: string;
  gray?: boolean;
  direction?: 'column' | 'row';
  grow?: boolean;
  overflow?: string;
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  return (
    <ExpandableAsideSectionContainer>
      <ExpandableAsideSectionHeader
        variant="flat"
        onClick={() => setIsExpanded(!isExpanded)}
        gray={gray}
      >
        <ExpandableAsideSectionTitle>{title}</ExpandableAsideSectionTitle>
        {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </ExpandableAsideSectionHeader>

      {isExpanded && (
        <ExpandableAsideSectionBody
          direction={direction}
          overflow={overflow}
          gray={gray}
          grow={grow}
        >
          {children}
        </ExpandableAsideSectionBody>
      )}
    </ExpandableAsideSectionContainer>
  );
};

export const ValidationMessagesSection = styled.div<
  PropsWithPropStyling<{ bottom?: string }>
>`
  position: sticky;
  bottom: ${(p) => p.bottom ?? '64px'};
  z-index: 4;
  ${propStyling}
`;

export const AsideSectionActions = styled(AsideSection as any)`
  height: 64px;
  align-items: center;
  padding: 0 24px;
  flex-direction: row;
  flex-shrink: 0;
  margin-top: -1px;
  border-top: 1px solid #e5e5e5;
  z-index: 3;
  position: sticky;
  bottom: 0;
`;

const AsideValidationBox = styled(AsideSection)`
  background: ${tombac.color('danger', 100)};
  color: ${tombac.color('danger', 600)};
  border-bottom: none;
  margin-top: -1px;
  padding: ${tombac.space(1.5)} ${tombac.space(3)};
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

export function AsideValidation({ children }: any) {
  return (
    <AsideValidationBox>
      <WarningIcon /> {children}
    </AsideValidationBox>
  );
}

export const Flex = styled(Box)<{
  direction?: 'column' | 'row';
  alignItems?: string;
}>`
  display: flex;
  flex-direction: ${(p) => p.direction};
  align-items: ${(p) => p.alignItems};
`;

export const Spacer = styled.div<{ width?: number; height?: number }>`
  flex-grow: ${(p) => (p.width || p.height ? 0 : 1)};
  width: ${(p) => (p.width ? tombac.space(p.width) : undefined)};
  height: ${(p) => (p.height ? tombac.space(p.height) : undefined)};
`;

const AsideItemActions = styled.div<{ active?: boolean }>`
  position: absolute;
  right: 3px;
  top: 3px;
  display: flex;
  opacity: ${(p) => (p.active ? 1 : 0)};
`;

const AsideItemBox = styled.div<{
  active?: boolean;
  interactive?: boolean;
  invalid?: boolean;
  height?: string;
}>`
  height: ${(p) => p.height};
  width: 100%;
  padding: ${tombac.space(1)} ${tombac.space(2)};
  border-radius: 3px;
  background: ${(p) => (p.active ? tombac.color('accent', 100) : '#FFF')};
  border: 1px solid
    ${(p) =>
      p.active ? tombac.color('accent', 500) : tombac.color('neutral', 400)};
  border-color: ${(p) => (p.invalid ? tombac.color('danger', 500) : '')};
  transition-duration: 0.1s;
  cursor: ${(p) => (p.interactive && !p.active ? 'pointer' : '')};
  position: relative;
  margin-bottom: ${tombac.space(1)};

  ${Heading} {
    color: ${(p) => (p.active ? tombac.color('accent', 600) : '')};
  }

  :hover {
    box-shadow: ${(p) =>
      p.interactive && !p.active ? '0 2px 7px 0 rgba(0, 0, 0, 0.08)' : ''};
    background-color: ${(p) =>
      p.interactive
        ? p.active
          ? tombac.color('accent', 100)
          : '#f2f2f2'
        : ''};
  }

  :active {
    box-shadow: none;
  }

  :hover ${AsideItemActions} {
    opacity: 1;
  }
`;

const AsideItemColorLabel = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  height: 100%;
  width: 8px;
  background: ${(p) => p.color};
`;

export function AsideItem({
  id,
  title,
  children,
  active,
  onClick,
  onDelete,
  onEdit,
  templateButton,
  prepend,
  menuItems,
  renaming,
  onTitleChange,
  invalid,
  height,
  labelColor,
  type,
  templateButtonDisabled = false,
}: {
  id?: string;
  title?: string;
  children?: any;
  active?: boolean;
  onClick?: () => any;
  onEdit?: () => any;
  onDelete?: () => any;
  editPath?: string;
  templateButton?: ReactNode;
  prepend?: ReactNode;
  menuItems?: ReactNode;
  renaming?: boolean;
  onTitleChange?: any;
  invalid?: boolean;
  height?: string;
  labelColor?: string;
  templateButtonDisabled?: boolean;
  type?: 'dateRange' | 'timeRange';
}) {
  const toggleRef = useRef<any>();
  const inputRef = useRef<any>();

  const updateTitle = () => {
    if (!inputRef.current) {
      return;
    }
    onTitleChange(inputRef.current.value);
  };

  return (
    <AsideItemBox
      id={id}
      active={active}
      invalid={invalid}
      onClick={onClick}
      interactive={onClick !== undefined}
      height={height}
    >
      <div>
        {labelColor && <AsideItemColorLabel color={labelColor} />}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {prepend}
          {title === undefined ? undefined : renaming ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                updateTitle();
              }}
            >
              <Input
                defaultValue={title}
                onBlur={updateTitle}
                ref={inputRef}
                autoFocus
                width="230px"
              />
            </form>
          ) : (
            <Heading
              level={5}
              style={{ fontWeight: 'bold', maxWidth: 'calc(100% - 80px)' }}
            >
              {title}
            </Heading>
          )}
        </div>
        {children !== undefined && <Box $marginTop="8u">{children}</Box>}
        <AsideItemActions active={active} onClick={(e) => e.stopPropagation()}>
          {onEdit && (
            <SimpleTooltip content="Edit">
              <Button shape="circle" size="s" variant="flat" onClick={onEdit}>
                <EditIcon />
              </Button>
            </SimpleTooltip>
          )}
          {/*fix for disabled button */}
          <Tooltip
            variant="inverted"
            usePortal
            placement="top"
            size="s"
            content={
              templateButtonDisabled
                ? `You have already saved this ${
                    type === 'dateRange' ? 'date range' : 'time range'
                  } as a template`
                : 'Save template'
            }
          >
            {({ ref, open, close }) => (
              <div ref={ref} onPointerEnter={open} onPointerLeave={close}>
                {templateButton}
              </div>
            )}
          </Tooltip>
          {onDelete && (
            <SimpleTooltip content="Delete">
              <Button shape="circle" size="s" variant="flat" onClick={onDelete}>
                <DeleteIcon />
              </Button>
            </SimpleTooltip>
          )}
          {menuItems && (
            <Menu
              trigger={({ toggle }: any) => {
                toggleRef.current = toggle;
                return (
                  <Button
                    shape="circle"
                    size="s"
                    variant="flat"
                    onClick={toggle}
                  >
                    <MenuKebabIcon />
                  </Button>
                );
              }}
            >
              <div onClick={() => toggleRef.current?.()}>{menuItems}</div>
            </Menu>
          )}
        </AsideItemActions>
      </div>
    </AsideItemBox>
  );
}

export const StepIcon = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: ${tombac.fontFamily};
  font-size: 12px;
  font-weight: bold;
  box-sizing: border-box;
  margin-right: 12px;
  background-color: ${tombac.color('accent', 500)};
  color: #fff;
`;

const FlexHeading = styled(Heading as any)`
  display: flex;
  align-items: center;
  width: 100%;
`;

const AsideTitleSection = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${tombac.color('neutral', 400)};
  padding: 0 24px;
  width: 100%;
  flex-shrink: 0;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export function AsideTitle({
  index,
  title,
  children,

  actions,
}: {
  index: number;
  title: string;
  templateType?: any;
  children?: ReactNode;
  actions?: ReactNode;
}) {
  return (
    <AsideTitleSection>
      <FlexHeading level={3} style={{ fontWeight: 'bold' }}>
        <StepIcon>{index}</StepIcon>
        {title}
        <Box $ml="auto">{actions}</Box>
      </FlexHeading>
      <Spacer />
      {children}
    </AsideTitleSection>
  );
}

export const ModalContent = styled.div`
  background: #fbfbfb;
`;

export const ModalHeader = styled.div`
  background: #fff;
  padding: 0 40px;
  display: flex;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid #e5e5e5;
`;

export const ModalForm = styled.div<{ height?: string }>`
  padding: 20px 40px 24px 40px;
  height: ${(p) => p.height};

  display: flex;
  flex-direction: column;
`;

export const ModalActions = styled.div`
  background: #fff;
  padding: 0 32px;
  display: flex;
  align-items: center;
  height: 64px;
  justify-content: flex-end;
  border-top: 1px solid #e5e5e5;
  position: sticky;
  bottom: -25px;
`;

export const Warning = styled.div`
  width: 100%;
  color: #ce0a0c;
`;

export const DivNoway = styled.div<{
  fontSize?: string;
  fontWeight?: string;
  color?: string;
}>`
  font-family: ${tombac.fontFamily};
  font-size: ${(p) => p.fontSize ?? '12px'};
  font-weight: ${(p) => p.fontWeight ?? 'normal'};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(p) => p.color ?? '#000000'};
`;

export const MapControl = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 10px;
  z-index: 1;
  background: #fff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
`;

export const AsideTitleButton = styled(Button)`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--accent-600);
  width: 100%;
` as typeof Button;
