import * as React from 'react';
import styled from 'styled-components';
import { InfoIcon } from 'tombac-icons';
import './RegionHelper.css';

interface Props {
  text?: React.ReactNode;
}

const Actions = styled.div`
  margin: 10px 192px 0 0;
  padding: 14px 12px 12px;
  border: solid 1px #e5e5e5;
  background-color: #ffffff;
  z-index: 10;
  position: absolute;
  left: 0;
  margin-left: 15px;
  top: 65px;
`;

const RegionHelper = ({ text }: Props) => (
  <div className="RegionHelper">
    <InfoIcon size={1} />
    <div className="RegionHelper-text">{text}</div>
  </div>
);

export const RegionSelectOptions = ({
  actions,
}: {
  actions?: React.ReactNode;
}) => {
  return <Actions>{actions}</Actions>;
};

export default RegionHelper;
