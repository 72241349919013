import { useEffect, useState } from 'react';
import useMap from './useMap';

export const useMapZoomActive = (threshold: number) => {
  const map = useMap();
  const [active, setActive] = useState(false);
  useEffect(() => {
    const handleZoom = () => {
      setActive(map.getZoom() >= threshold);
    };
    handleZoom();

    map.on('zoom', handleZoom);
    return () => {
      map.off('zoom', handleZoom);
    };
  }, [map]);

  return active;
};
