import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import center from '@turf/center';
import { featureCollection } from '@turf/helpers';
import tz from 'tz-lookup';
import { RegionDto } from '../../model/RegionDto';

export function timezoneFromRegions(dtoRegions: RegionDto[]): string {
  if (!dtoRegions || !dtoRegions.length) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  const geojson = featureCollection(dtoRegions);
  const regionsBbox = bboxPolygon(bbox(geojson));
  const regionsCenter = center(regionsBbox);
  const [lat, lng] = regionsCenter.geometry.coordinates;
  try {
    return tz(lng, lat);
  } catch (e) {
    return undefined;
  }
}
