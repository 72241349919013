import * as React from 'react';
import './ProgressBar.css';

interface ProgressBarProps {
  value: number;
  height?: number;
  done?: boolean;
  color?: string;
}
const ProgressBar: React.SFC<ProgressBarProps> = ({
  value,
  height,
  done,
  color,
}) => (
  <div className="ProgressBar">
    <div
      className={`ProgressBar__line ${done ? 'ProgressBar__line--done' : ''}`}
      style={{ width: `${Math.min(value, 100)}%`, height, background: color }}
    />
  </div>
);
export default ProgressBar;
