import { BBox } from '@turf/helpers';
import { fetchApi } from './api';

interface MapResponse {
  maps: MapInfo[];
}

export interface MapInfo {
  version: string;
  releaseTimestamp: number;
  type: string;
  strict?: boolean;
}

export const MapApi = {
  getMaps: (bBox: BBox, mapType?: string) => {
    const boundingBox = bBox.join();
    const mapTypeUrl = mapType === 'Orbis' ? '/OPEN_DSEG' : '';
    return fetchApi<MapResponse>(
      `/rest/maps${mapTypeUrl}?boundingBox=${boundingBox}`,
    ).then((it) =>
      it.maps.sort((a, b) => a.releaseTimestamp - b.releaseTimestamp),
    );
  },
};
