import { BBox, bbox, featureCollection, Geometry, point } from '@turf/turf';
import LimitsApi from 'api/LimitsApi';
import { MapApi, MapInfo } from 'api/MapApi';
import { Flex, Spacer } from 'components/UI/FormUI';
import useMap from 'hooks/useMap';
import debounce from 'lodash/debounce';
import { DateRangeDto } from 'model/TimeDto';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useMenu } from 'reducers/menuReducer';
import styled, { css } from 'styled-components';
import {
  Box,
  Button,
  Checkbox,
  Label,
  Text,
  ToggleGroup,
  tombac,
  TooltipOnHover,
} from 'tombac';
import {
  BackIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ForwardIcon,
  HelpIcon,
  InfoIcon as TombacInfoIcon,
  SpinnerIcon,
  WarningIcon,
} from 'tombac-icons';
import { MapsLoading } from './MapsLoading';
import './MultipleMapSelector.css';

export const SelectorBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

const ArrowButton = styled(Button)`
  &.disabled {
    cursor: not-allowed;
  }
`;

const MapVersions = styled.div`
  display: block;
  width: 100%;
  position: relative;
`;

export const SelectorHeader = styled.div`
  display: flex;
  align-items: center;
  height: 51px;
  padding: 0 8px;
  border-bottom: 1px solid #e5e5e5;
`;

const SelectorContent = styled.div`
  padding: 10px 16px 16px 16px;
`;

const MapVersionsBoxStyled = styled(Box)`
  overflow-y: visible;
  overflow-x: hidden;
  border: 1px solid ${tombac.color('neutral', 400)};
  scroll-padding-top: 30px;
`;

const MapRowStyled = styled.div<{ isActive: boolean }>`
  padding: 7px 15px;
  font-size: 12px;
  color: ${tombac.color('neutral', 700)};
  font-weight: 400;
  ${({ isActive }) =>
    isActive &&
    css`
      background: ${tombac.color('accent', 100)};
      color: ${tombac.color('accent', 500)};
      cursor: pointer;
    `}

  &:hover {
    background: ${tombac.color('accent', 100)};
    color: ${tombac.color('accent', 500)};
    cursor: pointer;
  }
`;

const ForwardIconStyled = styled(ForwardIcon)`
  transform: rotate(90deg); ;
`;
const BackIconStyled = styled(BackIcon)`
  transform: rotate(90deg); ;
`;

const TransparentBox = styled(Box)`
  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

type MapTypes = 'Genesis' | 'Orbis';

export const shouldRecommendMapVersion = (
  maps: MapInfo[],
  dateRanges: DateRangeDto[],
) => {
  if (!maps || maps.length === 0) {
    return () => false;
  }

  const datesSorted = dateRanges
    .flatMap((it) => [new Date(it.startDate), new Date(it.endDate)])
    .sort((a, b) => a.getTime() - b.getTime());

  const minDate = datesSorted[0];
  const maxDate = datesSorted[datesSorted.length - 1];

  if (minDate === undefined || maxDate === undefined) {
    return () => false;
  }

  let firstMap: MapInfo = [...maps]
    .map((it) => ({
      distance:
        it.strict && it.releaseTimestamp > maxDate.getTime()
          ? Infinity
          : Math.abs(it.releaseTimestamp - minDate.getTime()),
      map: it,
    }))
    .sort((a, b) => a.distance - b.distance)[0].map;

  let lastMap: MapInfo = [...maps]
    .map((it) => ({
      distance:
        it.strict && it.releaseTimestamp > maxDate.getTime()
          ? Infinity
          : Math.abs(it.releaseTimestamp - maxDate.getTime()),
      map: it,
    }))
    .sort((a, b) => a.distance - b.distance)[0].map;

  return (mapInfo: MapInfo): boolean => {
    if (!firstMap && !lastMap) {
      return false;
    }
    if (!firstMap) {
      return mapInfo.releaseTimestamp <= lastMap.releaseTimestamp;
    }
    if (!lastMap) {
      return mapInfo.releaseTimestamp >= firstMap.releaseTimestamp;
    }
    return (
      mapInfo.releaseTimestamp >= firstMap.releaseTimestamp &&
      mapInfo.releaseTimestamp <= lastMap.releaseTimestamp
    );
  };
};

interface MultipleMapSelectorProps {
  geometry?: Geometry;
  disabled?: boolean;
  disabledReason?: string;
}

const MultipleMapSelector: FC<MultipleMapSelectorProps> = ({
  geometry,
  disabled,
  disabledReason,
}) => {
  const [menu, setMenu] = useMenu();
  const map = useMap();
  const [maps, setMaps] = useState<MapInfo[]>([]);
  const [mapsLoading, setMapsLoading] = useState<'idle' | 'loading' | 'ready'>(
    'idle',
  );
  const loadedMapsBbox = useRef<BBox>();
  const [bounds, setBounds] = useState<BBox | undefined>();
  const [pageCursor, setPageCursor] = useState(0);
  const [hidden, setHidden] = useState(true);
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollBoxRef = useRef<HTMLDivElement>(null);

  const { mapTypeName } = menu;
  const limits = LimitsApi.use();

  const isOrbisAllowed = limits?.limits?.orbisMaps;
  const noMapsInViewPort = mapsLoading === 'ready' && maps.length === 0;

  const setMapVersion = (map: MapInfo) => {
    setMenu({ mapVersion: map.version, mapType: map.type });
  };

  const loadMaps = async (
    mapQueryBbox: BBox,
    isCurrent: { value: boolean },
  ) => {
    setMapsLoading('loading');
    const maps = await MapApi.getMaps(mapQueryBbox, mapTypeName);

    if (!isCurrent.value) {
      return;
    }

    const newMaps = maps
      .filter(
        (it) =>
          it.type === 'DSEG_NOSPLIT' ||
          it.type === 'OPEN_DSEG_NOSPLIT' ||
          it.type === 'OPEN_DSEG',
      )
      .sort((b, a) => a.releaseTimestamp - b.releaseTimestamp);

    setMaps((it) => {
      if (JSON.stringify(it) !== JSON.stringify(newMaps)) {
        return newMaps;
      }
      return it;
    });

    if (
      newMaps.length !== 0 &&
      !newMaps.some((it) => it.version === menu.mapVersion)
    ) {
      const map = [...newMaps].find(shouldRecommend) ?? newMaps[0];

      setMapVersion(map);
      setPageCursor(
        Math.min(
          newMaps.findIndex((it) => it.version === map.version),
          newMaps.length - pageWidth,
        ),
      );
    }
    setMapsLoading('ready');
    loadedMapsBbox.current = mapQueryBbox;
  };

  const setViewportBounds = () => {
    const bounds = map.getBounds();
    const [a, b] = bounds.toArray();
    const mapQueryBbox = bbox(featureCollection([point(a), point(b)]));
    return mapQueryBbox;
  };

  useEffect(() => {
    const viewportBounds = setViewportBounds();

    const mapQueryBbox = geometry ? bbox(geometry) : viewportBounds;

    if (mapQueryBbox === undefined) {
      return;
    }

    const isCurrent = { value: true };
    loadMaps(mapQueryBbox, isCurrent);

    return () => {
      isCurrent.value = false;
    };
  }, [mapTypeName]);

  useEffect(() => {
    if (!isOrbisAllowed) setMenu({ mapTypeName: 'Genesis' });
  }, [isOrbisAllowed]);

  // Update bounds on map movement
  useEffect(() => {
    const handleMove = debounce(() => {
      const bounds = map.getBounds();
      const [a, b] = bounds.toArray();
      const mapQueryBbox = bbox(featureCollection([point(a), point(b)]));

      setBounds((it) => {
        if (JSON.stringify(it) !== JSON.stringify(mapQueryBbox)) {
          return mapQueryBbox;
        }
        return it;
      });
    }, 1000);

    map.on('moveend', handleMove);

    handleMove();

    return () => {
      map.off('moveend', handleMove);
    };
  }, [map]);

  // Fetch new versions
  useEffect(() => {
    if (map.getZoom() < 8 && !geometry) {
      return;
    }
    const mapQueryBbox = geometry ? bbox(geometry) : bounds;
    if (loadedMapsBbox.current === mapQueryBbox) {
      return;
    }
    if (mapQueryBbox === undefined) {
      return;
    }

    const isCurrent = { value: true };
    loadMaps(mapQueryBbox, isCurrent);

    return () => {
      isCurrent.value = false;
    };
  }, [geometry, bounds]);

  const shouldRecommend = useMemo(
    () => shouldRecommendMapVersion(maps, menu.dateRanges),
    [maps, menu.dateRanges],
  );

  const MapTypePicker = () => (
    <ToggleGroup.Root
      onChange={(mapType: MapTypes) => setMenu({ mapTypeName: mapType })}
      size="xs"
      value={mapTypeName}
    >
      <ToggleGroup.Item value="Orbis">Orbis</ToggleGroup.Item>
      <ToggleGroup.Item value="Genesis">Genesis</ToggleGroup.Item>
    </ToggleGroup.Root>
  );

  const pageWidth = 4;

  // Set version to recommended on inital render
  const switched = useRef(false);
  useEffect(() => {
    if (switched.current) {
      return;
    }
    if (
      menu.mapVersion !== undefined &&
      menu.mapVersion !== '' &&
      maps.length
    ) {
      if (!maps.some((map) => map.version === menu.mapVersion)) {
        const selectedMaps = maps.filter((map) =>
          map.version.startsWith(menu.mapVersion!),
        );

        const map =
          [...selectedMaps]
            .sort((b, a) => b.releaseTimestamp - a.releaseTimestamp)
            .find(shouldRecommend) ?? selectedMaps[selectedMaps.length - 1];

        map && setMapVersion(map);
      }
      setPageCursor(
        Math.min(
          maps.findIndex((it) => it.version === menu.mapVersion),
          maps.length - pageWidth,
        ),
      );
      switched.current = true;
      return;
    }

    if (
      (menu.mapVersion === undefined || menu.mapVersion === '') &&
      maps.length > 0
    ) {
      const map =
        [...maps]
          .sort((a, b) => b.releaseTimestamp - a.releaseTimestamp)
          .find(shouldRecommend) ?? maps[maps.length - 1];
      setMapVersion(map);
      setPageCursor(
        Math.min(
          maps.findIndex((it) => it.releaseTimestamp === map.releaseTimestamp),
          maps.length - pageWidth,
        ),
      );
      switched.current = true;
    }
  }, [maps, menu.mapVersion]);

  useEffect(() => {
    if (menu.removeLineString) {
      setMenu({
        ...(menu.regions.length > 0 && { regions: menu.regionsWip }),
        removeLineString: false,
      });
    }
  }, [menu.removeLineString]);

  const regionsListHasLineString =
    menu.regionsWip.filter((r) => r.geometry.type === 'LineString').length >
      0 ||
    menu.regions.filter((r) => r.geometry.type === 'LineString').length > 0;

  const isBlockedMapVersionChange =
    menu.links.length > 0 ||
    (menu.regions.length > 0 && menu.type === 'SELECTED_LINK') ||
    regionsListHasLineString;

  if (noMapsInViewPort) {
    return (
      <>
        <SelectorBox>
          <Box
            $height="51px"
            $p="0 1sp"
            $display="flex"
            $alignItems="center"
            $justifyContent="space-around"
          >
            <Text>No maps available in this area</Text>
            {!isBlockedMapVersionChange && isOrbisAllowed && <MapTypePicker />}
          </Box>
        </SelectorBox>
      </>
    );
  }

  if ((mapsLoading === 'loading' && maps.length === 0) || !menu.mapVersion) {
    return (
      <>
        <SelectorBox>
          <SelectorHeader>
            {map.getZoom() < 8 && (
              <>
                <TombacInfoIcon width="14px" />
                <Text size="s" $ml="1sp">
                  Zoom in to see map versions
                </Text>
              </>
            )}
            {map.getZoom() >= 8 && <MapsLoading />}
          </SelectorHeader>
        </SelectorBox>
      </>
    );
  }

  const mapsToShow = maps.slice(
    Math.max(0, pageCursor),
    Math.min(maps.length, pageCursor + pageWidth),
  );

  const canSwitchMap = (direction?: 'next' | 'prev') => {
    if (disabled) {
      return false;
    }
    if (!direction) {
      return true;
    }
    const diff = direction === 'next' ? 1 : -1;

    const mapIndex = maps.findIndex(
      (it) => menu.mapVersion === it.version && menu.mapType === it.type,
    );
    const mapsLength = maps.length;
    const newIndex = mapIndex + diff;
    const canMove = newIndex >= 0 && newIndex < mapsLength;

    return canMove;
  };

  const switchMap = (direction: 'next' | 'prev') => {
    if (!canSwitchMap(direction)) {
      return;
    }
    const diff = direction === 'next' ? 1 : -1;

    const mapIndex = maps.findIndex(
      (it) => menu.mapVersion === it.version && menu.mapType === it.type,
    );
    const mapsLength = maps.length;
    const newIndex = mapIndex + diff;
    const canMove = newIndex >= 0 && newIndex < mapsLength;

    if (!canMove) {
      return;
    }

    let newCursor = pageCursor;

    const shouldMovePageCursor =
      newIndex < Math.max(0, pageCursor) ||
      newIndex >= Math.min(mapsLength, pageCursor + pageWidth);

    if (shouldMovePageCursor) {
      const isAhead = newIndex >= pageCursor + pageWidth;
      newCursor = isAhead ? newIndex - pageWidth + 1 : newIndex;
    }

    setMapVersion(maps[newIndex]);
    setPageCursor(newCursor);
    direction === 'next'
      ? (scrollBoxRef.current!.style.scrollPaddingTop = '30px')
      : (scrollBoxRef.current!.style.scrollPaddingTop = '86px');
    scrollRef.current!.scrollIntoView();
  };

  return (
    <>
      <SelectorBox>
        <SelectorHeader>
          <Label $ml="1sp" $mr="1sp">
            Map version: {menu.mapVersion}
          </Label>
          <TooltipOnHover
            variant="default"
            placement="top"
            content={
              <Text $width="300u" fontSize={14} lineHeight={1.4}>
                Selecting the best map version for your analysis can help you
                with better map matching of TomTom data to the road network.
                Older map enables you to analyze traffic information based on
                older road geometries. Newer map allows you to perform analysis
                on the up-to-date road network.
              </Text>
            }
          >
            <HelpIcon />
          </TooltipOnHover>
          <Spacer />

          {!isBlockedMapVersionChange && isOrbisAllowed && <MapTypePicker />}

          <Button
            $pr="0"
            variant="flat"
            size="xs"
            onClick={() => setHidden((it) => !it)}
          >
            {hidden ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Button>
        </SelectorHeader>
        <SelectorContent hidden={hidden} title={disabledReason}>
          {isBlockedMapVersionChange ? (
            <Box $display="flex" $alignItems="start">
              <WarningIcon color="rgba(249, 176, 35, 1)" />
              <Box $ml="1sp">
                <Text fontSize={12} $color={tombac.color('alert', 700)}>
                  It is not possible to change the map version when road
                  segments are already selected. <br /> Please delete all road
                  segments first.
                </Text>
                {regionsListHasLineString && (
                  <Button
                    $mt="1sp"
                    variant="alert"
                    onClick={() => {
                      const onlyPolygons =
                        menu.regionsWip.length !== 0
                          ? menu.regionsWip.filter(
                              (r) => r.geometry.type !== 'LineString',
                            )
                          : menu.regions.filter(
                              (r) => r.geometry.type !== 'LineString',
                            );
                      setMenu({
                        removeLineString: true,
                        regionsWip: onlyPolygons,
                      });
                      // menu.add
                    }}
                  >
                    Delete road segment(s)
                  </Button>
                )}
              </Box>
            </Box>
          ) : (
            <>
              <Flex $alignItems="center" $marginRight="24u">
                <div style={{ marginRight: 'auto' }}>
                  <Text fontSize={12} $display="inline" $mr="1sp">
                    Change map
                  </Text>
                  <ArrowButton
                    disabled={!canSwitchMap('prev')}
                    onClick={() => switchMap('prev')}
                    shape="circle"
                    size="xs"
                    className={!canSwitchMap('prev') ? 'disabled' : undefined}
                  >
                    <BackIconStyled />
                  </ArrowButton>
                  <ArrowButton
                    $ml="0.5sp"
                    disabled={!canSwitchMap('next')}
                    shape="circle"
                    size="xs"
                    onClick={() => switchMap('next')}
                    className={!canSwitchMap('next') ? 'disabled' : undefined}
                  >
                    <ForwardIconStyled />
                  </ArrowButton>
                </div>
              </Flex>

              <Flex alignItems="center" $mt="2sp">
                <MapVersions>
                  <MapVersionsBoxStyled
                    $height="230px"
                    $overflow="scroll-y"
                    ref={scrollBoxRef}
                  >
                    {mapsLoading === 'loading' ? (
                      <TransparentBox>
                        <SpinnerIcon spin />
                      </TransparentBox>
                    ) : (
                      ''
                    )}

                    {maps.map((m) => (
                      <MapRowStyled
                        key={m.version}
                        isActive={m.version === menu.mapVersion}
                        onClick={() => setMapVersion(m)}
                        ref={m.version === menu.mapVersion ? scrollRef : null}
                      >
                        {m.version} {shouldRecommend(m) && '(recommended)'}
                      </MapRowStyled>
                    ))}
                  </MapVersionsBoxStyled>
                </MapVersions>
                {mapsToShow.length === 2 &&
                  mapsToShow[0].version === '2016.12' && (
                    <>
                      <Spacer width={2} />
                      <TooltipOnHover
                        variant="inverted"
                        placement="top"
                        content={
                          <>
                            Only 2 map versions are available, when <br />
                            selected date range is older than 2 years
                          </>
                        }
                      >
                        <TombacInfoIcon color="#F9B022" />
                      </TooltipOnHover>
                      <Box>Why do you see only 2 maps?</Box>
                    </>
                  )}
              </Flex>
            </>
          )}
          {menu.showRoadNetworkSwitch && (
            <Box
              $display="flex"
              $alignItems="center"
              $justifyContent="space-between"
              $mt="2sp"
            >
              <Label>Road network of the selected map version</Label>
              <Checkbox
                checked={menu.showRoadNetwork}
                variant="toggle"
                onChange={(e) => {
                  setMenu({ showRoadNetwork: e.target.checked });
                }}
              />
            </Box>
          )}
        </SelectorContent>
      </SelectorBox>
    </>
  );
};

export default MultipleMapSelector;
