type range = [number, number];

function updateRange(range: range, value: number): range {
  if (range == null) {
    return [value, value];
  }
  return [Math.min(range[0], value), Math.max(range[1], value)];
}

export function calculateFlowRanges(
  slice: any,
  fromLength: number,
  toLength: number,
  hasExternals: boolean,
) {
  let regular: range = null;
  let internal: range = null;
  let external: range = null;

  slice.forEach((row: any, fromIx: number) => {
    row.forEach((flow: number, toIx: number) => {
      if (
        hasExternals &&
        (fromIx === fromLength - 1 || toIx === toLength - 1)
      ) {
        if (fromIx !== toIx) {
          external = updateRange(external, flow);
        }
      } else if (fromIx === toIx) {
        internal = updateRange(internal, flow);
      } else {
        regular = updateRange(regular, flow);
      }
    });
  });

  return {
    external,
    internal,
    regular,
  };
}

export function getLabel(labels: string[], index: number) {
  if (index < labels.length) {
    return labels[index];
  }
  if (index === labels.length) {
    return 'External';
  }
  return 'Unnamed';
}
