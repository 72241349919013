import React from 'react';
import useMap from './useMap';

export const useMapSymbolVisibility = ({ poi, shields }) => {
  const map = useMap();
  React.useEffect(() => {
    const style = map.getStyle();

    style.layers.forEach((layer) => {
      if (
        layer.type === 'symbol' &&
        layer.source === 'vectorTiles' &&
        layer['source-layer'] === 'Point of Interest'
      ) {
        map.setLayoutProperty(layer.id, 'visibility', poi ? 'visible' : 'none');
      }

      if ((layer as any).metadata === 'group:shield') {
        map.setLayoutProperty(
          layer.id,
          'visibility',
          shields ? 'visible' : 'none',
        );
      }
    });
  }, [poi, map]);
};
