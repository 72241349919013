import { centroid, coordAll } from '@turf/turf';
import { LimitsWithUsage } from 'api/LimitsApi';
import { appInsights } from 'logic/tracking';
import { AnalysisType } from 'model/AnalysisDto';
import { RegionDto } from 'model/RegionDto';
import { calculateArea } from './areaValidation';
import { validateRegion, ValidationStatus } from './validation';

export async function reverseGeocode(coords: [number, number]) {
  try {
    const endpoint = `https://api.tomtom.com/search/2/reverseGeocode/${coords[1]},${coords[0]}.json?key=1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv`;
    const response = await fetch(endpoint);
    const result = await response.json();
    return result;
  } catch (e) {
    appInsights.trackException(e);
    return undefined;
  }
}

export function detectName(region: RegionDto) {
  const possibleNameProperties = ['name', 'NAME', 'Name'];
  const nameProperty = possibleNameProperties.find(
    (it) => region.properties[it],
  );
  return nameProperty ? region.properties[nameProperty] : undefined;
}

export const getRegions = async (regions: RegionDto[], autoName = false) => {
  for (let i = 0; i < regions.length; i++) {
    const region = regions[i];
    let name = detectName(region);

    if (!name && autoName) {
      const area = calculateArea([regions[i]]);
      if (area <= 0.204) {
        const coords = centroid(regions[i]).geometry.coordinates;
        const result = await reverseGeocode(coords as any);
        if (result.addresses[0].address.streetName) {
          name = result.addresses[0].address.streetName;
        }
      }
    }

    region.properties.name = name;
  }
  return regions;
};

export const applyNewRegions = (
  state: { regions: RegionDto[]; type: AnalysisType },
  action: { regions: RegionDto[]; limits: LimitsWithUsage },
) => {
  const { regions, limits } = action;
  const oldRegions: string[] = state.regions.map((it) => it.properties.name).filter(Boolean).map((el) => `${el}`);
  const newRegions = regions.map((it, index) => {
    if (it.geometry.type === 'LineString') {
      it.properties.bidirectional = true;
    }

    const newProperties: any = { ...it.properties };
    if (it.properties.validationResult === undefined) {
      newProperties.validationResult = validateRegion(
        it,
        state.type,
        limits.limits.allowedArea,
      );
    }

    if (it.properties.name === undefined) {
      newProperties.name = `Region ${index + 1 + state.regions.length}`;
      return { ...it, properties: newProperties };
    }

    const sameStreets = oldRegions.filter((oldreg) =>
      oldreg.startsWith(it.properties.name),
    );

    for (let i = 2; i <= sameStreets.length + 1; i++) {
      if (
        sameStreets.find((streetName) => streetName === it.properties.name) &&
        !sameStreets.find(
          (streetName) =>
            streetName === `${it.properties.name.slice(0, -3)}(${i})`,
        )
      ) {
        newProperties.name = `${it.properties.name} (${i})`;
      }
    }
    return { ...it, properties: newProperties };
  });
  return [...state.regions, ...newRegions];
};

export const sameRegion = (a: RegionDto, b: RegionDto) => {
  if (a.geometry.type !== b.geometry.type) {
    return false;
  }
  if (a.geometry.coordinates.length !== b.geometry.coordinates.length) {
    return false;
  }
  const coordsA = coordAll(a);
  const coordsB = coordAll(b);

  const length = coordsA.length;
  let i;
  for (i = 0; i < length; i++) {
    if (coordsA[i][0] !== coordsB[i][0] && coordsA[i][1] !== coordsB[i][1]) {
      return false;
    }
  }

  return true;
};

export const getDisplayErrorModal = (
  regions: RegionDto[],
  hoveredRegion: number,
) => {
  const region = regions[hoveredRegion];
  return (
    hoveredRegion > -1 &&
    region &&
    region.properties &&
    region.properties.validationResult.status === ValidationStatus.INVALID
  );
};

export const getCenter = (region: RegionDto) => {
  if (region.geometry.type === 'Point') {
    const cords = region.geometry.coordinates as [number, number];
    return [cords[1], cords[0]];
  }
  const coords = centroid(region).geometry.coordinates;
  return [coords[0], coords[1]];
};
