import centroid from '@turf/centroid';
import AnalysisApi from 'api/AnalysisApi';
import GlMap from 'components/Map/GlMap';
import { useFeatureState } from 'hooks/useFeatureState';
import { useLayerEvent } from 'hooks/useLayerEvent';
import useLayers from 'hooks/useLayers';
import { useMapCenterEffect } from 'hooks/useMapCenterEffect';
import { useMapEvent } from 'hooks/useMapEvent';
import { useSubmit } from 'hooks/useSubmit';
import { RegionDto } from 'model/RegionDto';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input, TooltipOnHover, useToasts } from 'tombac';
import { ErrorIcon, SearchIcon } from 'tombac-icons';
import { useAnalysisContext } from '../AnalysisViewPage';
import './RenameRegions.css';
import { useValidation } from 'hooks/useValidation';
import { regionNamesRequirements } from 'components/AnalysisNew/Requirements/requirements';
import { ValidationMessagesSection } from 'components/UI/FormUI';
import { isLayerLineString } from 'components/Map/mapUtils';
import { TextEllipsis } from 'components/UI/UI';

const textLayer: mapboxgl.Layer = {
  id: 'regions-name',
  type: 'symbol',
  layout: {
    'text-field': '{name}',
    'text-font': ['Noto-Regular'],
    'text-offset': [0, 0.1],
    'text-size': 18,
  } as any,
  paint: {
    'text-opacity': 1,
    'text-opacity-transition': {
      duration: 1,
      delay: 0,
    },
    'icon-opacity-transition': {
      duration: 0,
    },
    'text-halo-color': 'rgba(255,255,255,0.9)',
    'text-halo-width': 1.5,
    'text-color': ['get', 'text-color'],
  },
};
const outlineLayer: mapboxgl.Layer = {
  id: 'regions-outline',
  type: 'line',
  layout: {
    'line-cap': 'round',
  } as any,
  paint: {
    'line-color': [
      'case',
      ['boolean', ['feature-state', 'focus'], false],
      '#61ade0',
      '#888',
    ],
    'line-width': ['case', isLayerLineString, 7, 1],
  },
};
const fillLayer: mapboxgl.Layer = {
  id: 'regions-fill',
  type: 'fill',
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'focus'], false],
      '#61ade0',
      '#888',
    ],
    'fill-opacity': ['case', ['!=', ['geometry-type'], 'LineString'], 0.2, 0],
  },
};

const regionLayers = [outlineLayer, fillLayer];
const namesLayers = [textLayer];

function RegionsLayer({
  regions,
  names,
  focusOnRegion,
  onChangeFocusRegion,
}: {
  regions: RegionDto[];
  names: string[];
  focusOnRegion?: number;
  onChangeFocusRegion: (id: number) => void;
}): any {
  const regionCollection = useMemo(
    () => ({
      type: 'FeatureCollection' as const,
      features: regions.map((r, i) => ({ ...r, id: i })),
    }),
    [regions],
  );
  const centerCollection = useMemo(
    () => ({
      type: 'FeatureCollection' as const,
      features: regions.map((region) => centroid(region)),
    }),
    [regions],
  );
  const namesCollection = useMemo(
    () => ({
      type: 'FeatureCollection' as const,
      features: centerCollection.features.map((region, i) => ({
        ...region,
        properties: {
          ...region.properties,
          name: names[i],
          'text-color': focusOnRegion === i ? '#61ade0' : '#333',
        },
      })),
    }),
    [centerCollection, names, focusOnRegion],
  );

  useMapCenterEffect(regionCollection, false);
  useMapCenterEffect(
    focusOnRegion === undefined ? undefined : regions[focusOnRegion],
    true,
    150,
  );

  useMapEvent('click', () => onChangeFocusRegion(undefined));
  useLayers('regions', regionLayers, regionCollection);

  const onRegionClick = (e: any) => {
    if (e.features.length) {
      onChangeFocusRegion(e.features[0].id);
    }
  };

  useLayerEvent('click', fillLayer.id, onRegionClick);
  useLayerEvent('click', outlineLayer.id, onRegionClick);

  useLayers('region-names', namesLayers, namesCollection);
  useFeatureState(
    'regions',
    '' + focusOnRegion,
    { focus: true },
    { focus: false },
  );

  return null;
}

interface Props {
  onClose: () => void;
}
function RenameRegions({ onClose }: Props) {
  const { analysis, refresh } = useAnalysisContext();
  const regions = analysis.regions;

  const { addToast } = useToasts();
  const originalNames = useMemo(() => regions.map((r) => r.properties.name), [
    regions,
  ]);
  const [names, setNames] = useState(originalNames);
  const [focusRegion, setFocusRegion] = useState<number | undefined>(undefined);
  const validation = useValidation(
    regionNamesRequirements,
    { names },
    () => {},
  );

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, []);

  const onNameChange = (i: number) => (e: any) => {
    const copy = [...names];
    copy[i] = e.target.value;
    setNames(copy);
  };

  const [submit, submitting] = useSubmit(async () => {
    if (!validation.canSubmit()) return;
    try {
      await AnalysisApi.renameRegions(analysis.info.id, names);
      refresh(true);
      onClose();
      addToast('Saved regions', 'success');
      location.reload();
    } catch (e) {
      addToast('Failed to save regions', 'danger');
    }
  });

  // Focus region by search
  const [search, setSearch] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  useEffect(() => {
    if (search.length) {
      const n = names
        .map((name, i) => ({ name, i }))
        .find((x) => x.name.toLowerCase().includes(search.toLowerCase()));
      if (n !== undefined) {
        setFocusRegion(n.i);
      }
    }
  }, [search]);

  // Focus or scroll editable input
  const inputRef = useRef<any>(undefined);
  useEffect(() => {
    if (searchActive) {
      if (inputRef.current) {
        const input = document.querySelector('#rename-input');
        input.scrollIntoView();
      }
      return;
    }
    if (focusRegion !== undefined) {
      inputRef.current.focus();
    }
  }, [focusRegion]);

  const errorTooltip = (
    <TooltipOnHover
      content="Region name can not be empty"
      placement="top"
      variant="danger"
    >
      <ErrorIcon
        color="#B22222"
        fixedWidth={false}
        style={{ display: 'block' }}
      />
    </TooltipOnHover>
  );

  return (
    <div className="RenameRegions">
      <div className="RenameRegions__side">
        <h2>Rename regions</h2>
        <Input
          $width="100%"
          prepend={<SearchIcon />}
          value={search}
          $mb="1sp"
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setSearchActive(true)}
          onBlur={() => setSearchActive(false)}
        />

        <div className="RenameRegions__list">
          {names.map((name, i) =>
            focusRegion === i ? (
              <Input
                $width="100%"
                id="rename-input"
                key={i}
                value={name}
                onChange={onNameChange(i)}
                invalid={name.length === 0}
                append={name.length === 0 && errorTooltip}
                ref={inputRef}
              />
            ) : (
              <div
                key={i}
                onClick={() => setFocusRegion(i)}
                className="RenameRegions__region"
              >
                {name.length === 0 ? (
                  errorTooltip
                ) : (
                  <TextEllipsis text={name} />
                )}
              </div>
            ),
          )}
        </div>
        <div className="RenameRegions__actions">
          <ValidationMessagesSection
            style={{ marginBottom: '20px', width: '260px' }}
          >
            {validation.messages}
          </ValidationMessagesSection>
          <Button onClick={submit} busy={submitting} variant="primary">
            Save
          </Button>
          <Button onClick={onClose} variant="flat">
            Cancel
          </Button>
        </div>
      </div>
      <div className="RenameRegions__map">
        <GlMap mapStyle="basic_main" independent>
          <RegionsLayer
            regions={regions}
            names={names}
            focusOnRegion={focusRegion}
            onChangeFocusRegion={setFocusRegion}
          />
        </GlMap>
      </div>
    </div>
  );
}

export default RenameRegions;
