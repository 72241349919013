import styled from 'styled-components';
import { FormGroup, Label, TooltipOnHover, tombac } from 'tombac';
import { WarningIcon } from 'tombac-icons';

const FrcBox = styled.div<{ active?: boolean }>`
  height: ${tombac.space(4)};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  font-family: ${tombac.fontFamily};
  font-size: 14px;
  font-weight: 600;
  background-color: ${(p) =>
    p.active ? tombac.color('neutral', 900) : 'none'};
  color: ${(p) =>
    p.active ? tombac.color('neutral', 100) : tombac.color('neutral', 900)};

  :hover {
    background-color: ${(p) =>
    p.active ? tombac.color('neutral', 700) : tombac.color('neutral', 400)};
  }

  margin-right: 4px;
`;

interface Props {
  label: string;
  frcsValues: number[];
  selectedFrcs: number[];
  setSelectedFrcs: (frcs: number[]) => void;
}

export const FrcSelector = ({
  label,
  frcsValues,
  selectedFrcs,
  setSelectedFrcs,
}: Props) => {
  const isFrcSelected = (frc: number): boolean => {
    return selectedFrcs.some((it) => it == frc);
  };

  const toggleFrc = (frc: number) => {
    if (isFrcSelected(frc)) {
      setSelectedFrcs(selectedFrcs.filter((it) => it != frc));
    } else {
      setSelectedFrcs([...selectedFrcs, frc]);
    }
  };

  return (
    <FormGroup
      label={
        <>
          <Label
            style={{
              display: 'grid',
              gridTemplateColumns: `${selectedFrcs.length === 0 ? 'max-content 1fr' : '1fr'}`,
              alignItems: 'center',
              columnGap: '4px'
            }}
          > {label}
          {selectedFrcs.length === 0 && (
            <TooltipOnHover
              content={'Select at least one road class'}
              size="s"
              variant="danger"
              placement="top"
            >
              <WarningIcon size="xs" color="#de1c12" />
            </TooltipOnHover>
          )}
          </Label>{' '}
        </>
      }
    >
      {frcsValues.map((frc) => (
        <FrcBox
          key={frc}
          active={isFrcSelected(frc)}
          onClick={() => toggleFrc(frc)}
        >
          {frc}
        </FrcBox>
      ))}
    </FormGroup>
  );
};
