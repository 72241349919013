import { Component, Fragment } from 'react';
import { RegionSelector } from '../model/RegionSelector';
import { polygon, point, lineString } from '@turf/helpers';
import RegionHelper from '../RegionHelper/RegionHelper';
import { RegionDto } from 'model/RegionDto';
import DisplayRegions from '../../../Map/Layers/DisplayRegions';
import { withMap } from 'components/Map/Layers/withMap';

interface LatLng {
  lat: number;
  lng: number;
}

interface State {
  polygon?: RegionDto;
  points?: LatLng[];
}

const makePolygon = (points: LatLng[]) => {
  if (points.length === 1) {
    const { lat, lng } = points[0];
    return point([lat, lng]);
  }
  if (points.length === 2) {
    return lineString(points.map(({ lat, lng }) => [lng, lat]));
  }
  const newPoints = [...points, points[0]];
  return polygon([newPoints.map(({ lat, lng }) => [lng, lat])]);
};
class PolygonSelector extends Component<RegionSelector, State> {
  state: State = {
    polygon: undefined,
    points: [],
  };

  componentDidMount() {
    const map: mapboxgl.Map = this.props.map;

    map.on('click', this.handleClick);
    map.on('mousemove', this.handleMove);
    map.on('contextmenu', this.handleEnd);
  }

  componentWillUnmount = () => {
    const map: mapboxgl.Map = this.props.map;

    map.off('click', this.handleClick);
    map.off('mousemove', this.handleMove);
    map.off('contextmenu', this.handleEnd);
  };

  handleClick = (e: any) => {
    const { points } = this.state;

    const newPoints = [...points, e.lngLat];
    const newPolygon = makePolygon(newPoints);

    this.setState({ polygon: newPolygon, points: newPoints });
  };

  handleMove = (e: any) => {
    if (!this.state.points.length) {
      return;
    }

    const newPoints = [...this.state.points];
    newPoints.push(e.lngLat);

    const polygon = makePolygon(newPoints);

    return this.setState({ polygon });
  };

  handleEnd = () => {
    if (this.state.points.length === 0) return;
    const polygon = makePolygon(this.state.points);

    this.props.onSelect([polygon], true);
    this.setState({ polygon: undefined, points: [] });
  };

  render() {
    let text = 'Click to select first point';
    if (this.state.polygon) {
      text = 'Click to select points. Right click to end.';
    }
    return (
      <Fragment>
        <RegionHelper text={text} />
        <DisplayRegions
          isEditing
          dtoRegions={[this.state.polygon]}
          regionNames={false}
        />
      </Fragment>
    );
  }
}

export default withMap(PolygonSelector);
