import { useEffect } from 'react';
import useMap from './useMap';

export const useFeatureState = (
  source: string,
  id: string,
  v: any,
  defaultValue: any,
) => {
  const map = useMap();

  useEffect(() => {
    if (
      id === 'undefined' ||
      id === undefined ||
      map.getSource(source) === undefined
    ) {
      return;
    }
    map.setFeatureState({ source, id }, v);
    return () => {
      if (map.getSource(source) === undefined) return;
      map.setFeatureState({ source, id }, defaultValue);
    };
  }, [source, id, v]);
};
