import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { MapAside } from 'components/UI/UI';
import { AnalysisStatus, AnalysisType } from 'model/AnalysisDto';
import { useIsAdmin } from 'user';
import { Dates } from './Sections/Dates';
import { Organizations } from './Sections/Organizations';
import { Regions } from './Sections/Regions';
import { Results } from './Sections/Results';
import { SelectedLink } from './Sections/SelectedLink';
import { SidebarHeader } from './Sections/SidebarHeader';
import { Statuses } from './Statuses/Statuses';
import { Summary } from './Sections/Summary';
import { Times } from './Sections/Times';
import { DataSource } from 'model/AnalysisParameters';
import { WarningMessage } from '../WarningMessage';
import { AnalysisMapModalActions } from './analysisMap.types';
import { isSelectedLinkRegion } from 'components/SelectedLinkPage/utils';
import { SelectedRegion } from './Sections/SelectedRegion';

interface Props {
  isShared: boolean;
  modalActions: AnalysisMapModalActions;
}

export const AnalysisMapSidebar = ({ isShared, modalActions }: Props) => {
  const { analysis } = useAnalysisContext();
  const isAdmin = useIsAdmin();
  const adminCanViewResults =
    isAdmin &&
    !isShared &&
    (analysis.info.status === AnalysisStatus.ENDED ||
      analysis.info.status === AnalysisStatus.REJECTED);

  return (
    <MapAside width="308px" id="parameters-aside">
      {analysis.info.dataSources === DataSource.LIMITED && (
        <WarningMessage>Report based on a lower volume of data</WarningMessage>
      )}
      <SidebarHeader
        isShared={isShared}
        showRenamingModal={modalActions.openRenamingModal}
      />
      <Statuses isShared={isShared} modalActions={modalActions} />
      {(analysis.info.status === AnalysisStatus.ACCEPTED ||
        adminCanViewResults) && <Results />}
      <Summary modalActions={modalActions} />
      <Dates />
      <Times />
      {analysis.info.type === AnalysisType.FlowMatrix ? (
        <Regions
          openRenamingRegionsModal={modalActions.openRenamingRegionsModal}
          isShared={isShared}
        />
      ) : isSelectedLinkRegion(analysis) ? (
        <SelectedRegion />
      ) : (
        <SelectedLink />
      )}
      <Organizations />
    </MapAside>
  );
};
