import ProgressBar from 'components/UI/ProgressBar__old/ProgressBar';
import { GreyText } from 'components/UI/Text.style';
import styled from 'styled-components';
import { Label, tombac } from 'tombac';
import { WarningIcon } from 'tombac-icons';

const ProgressValueText = styled(GreyText)<{ isError: boolean }>`
  color: ${({ isError }) =>
    isError ? tombac.color('danger') : tombac.color('neutral', 700)};
`;

const BoldValueText = styled(GreyText)<{ isError: boolean }>`
  color: ${({ isError }) =>
    isError ? tombac.color('danger') : tombac.color('neutral', 900)};
  font-weight: 500;
`;

const ProgressContainer = styled.div`
  margin-top: 8px;
`;
const ProgressHeader = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const ProgressValue = styled.div`
  margin-left: auto;
`;
export function Progress({
  label,
  value,
  max,
  unit,
  valueLabel,
  maxLabel,
}: {
  label: string;
  value: number;
  max: number;
  unit?: string;
  valueLabel?: JSX.Element;
  maxLabel?: JSX.Element;
}) {
  const isMaxValueExceeded = value > max;

  return (
    <ProgressContainer>
      <ProgressHeader>
        <Label>
          {label}{' '}
          {isMaxValueExceeded && <WarningIcon size="xs" color="#de1c12" />}
        </Label>
        <ProgressValue>
          <ProgressValueText isError={isMaxValueExceeded}>
            <BoldValueText isError={isMaxValueExceeded}>
              {valueLabel ? valueLabel : value}
            </BoldValueText>
            /{maxLabel ? maxLabel : max} {unit}
          </ProgressValueText>
        </ProgressValue>
      </ProgressHeader>
      <ProgressBar done={isMaxValueExceeded} value={(value / max) * 100} />
    </ProgressContainer>
  );
}
