import { Feature, Point } from '@turf/turf';
import { useEffect, useState } from 'react';
import arrowIncoming from './assets/arrow-incoming.svg';
import arrowOutgoing from './assets/arrow-outgoing.svg';
import arrowTwoWay from './assets/arrow-two-way.svg';
import { Circles, IconImage, Source, Symbols } from './MapboxComponents';
import { CursorHint } from './CursorHint';
import { useMapEvent } from 'hooks/useMapEvent';
import { queryFeatures } from 'components/Map/mapUtils';
import { Box, Caption, Label } from 'tombac';
import useMap from 'hooks/useMap';
import { ReverseGeocodeApi } from 'api/ReverseGeocodeApi';

const layers = {
  intersectionsPoints: 'intersections-points',
};

interface Props {
  intersections: Feature<Point>[];
}

export const RegionIntersectionFeature = ({ intersections }: Props) => {
  const [hover, _setHover] = useState<Feature<Point>>(undefined);
  const [hoverAddress, setHoverAddress] = useState<string>(undefined);

  useEffect(() => {
    const fetchAddress = async () => {
      const coords = hover.geometry.coordinates as [number, number];
      const address =
        (await ReverseGeocodeApi.getRoadName(coords)) ?? 'Segment';
      setHoverAddress(address);
    };

    if (hover) {
      fetchAddress();
    }
  }, [hover]);

  const map = useMap();

  useMapEvent('mousemove', (e) => {
    let featuresIds = queryFeatures(e, [layers.intersectionsPoints], 5).map(
      (it) => it.feature.id,
    );

    map.getCanvas().style.cursor = featuresIds.length > 0 ? 'pointer' : '';

    _setHover(intersections[featuresIds[0]]);
  });

  return (
    <>
      {hover && (
        <CursorHint>
          <Box $display="flex" $flexDirection="column">
            <Caption $color="#fff">
              Name: <Label $color="#fff">{hoverAddress}</Label>{' '}
            </Caption>
            <Caption $color="#fff">
              Road class: <Label $color="#fff">{hover.properties.frc}</Label>
            </Caption>
          </Box>
        </CursorHint>
      )}

      <Source id={layers.intersectionsPoints} data={intersections} generateId>
        <Circles
          id={layers.intersectionsPoints}
          circle-color="rgba(0, 170, 255, 1)"
          circle-radius={10}
        />
        <IconImage id="arrow-incoming" src={arrowIncoming} />
        <IconImage id="arrow-outgoing" src={arrowOutgoing} />
        <IconImage id="arrow-two-way" src={arrowTwoWay} />
        <Symbols
          icon-size={{
            stops: [
              [16, 0.5],
              [18, 0.75],
              [20, 1],
            ],
          }}
          icon-image={[
            'case',
            ['==', ['get', 'type'], 'INCOMING'],
            'arrow-incoming',
            ['==', ['get', 'type'], 'OUTGOING'],
            'arrow-outgoing',
            'arrow-two-way',
          ]}
          icon-rotate={['get', 'bearing']}
          symbol-avoid-edges
          symbol-placement="point"
          icon-allow-overlap
        />
      </Source>
    </>
  );
};
