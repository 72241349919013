import { convertFromDto } from '../logic/region/requestConversion';
import { convertDtoToGeoJson } from '../logic/region/dtoConverting';
import { RegionDto } from '../model/RegionDto';

enum RegionVersion {
  V0,
  V1, // GeoJSON Feature: RegionDto
}

const getRegionsVersion = (regions: RegionDto[] | { shape?: unknown; name?: string; }[]): RegionVersion | undefined => {
  // Assume that every region is the same format
  const region = regions[0];

  if ('type' in region && region.type === 'Feature') {
    return RegionVersion.V1;
  }
  if ('shape' in region && region.shape && 'name' in region && region.name) {
    return RegionVersion.V0;
  }
};

const handleRegionVersions = (regions: RegionDto[] | { shape?: unknown; name?: string; }[]): RegionDto[] => {
  const version = getRegionsVersion(regions);

  switch (version) {
    case RegionVersion.V1:
      return regions as RegionDto[];
    case RegionVersion.V0:
      return regions
        .map(convertFromDto)
        .map(convertDtoToGeoJson as any) as RegionDto[];;

    default:
      return [];
  }
};

export { handleRegionVersions };
