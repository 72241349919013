import { DataType, VehicleTypes } from 'fontModels/DataSource';
import { AnalysisType } from '../../model/AnalysisDto';
import {
  AnalysisRequest,
  DataSource,
  FlowLinksAnalysisRequest,
  FlowMatrixAnalysisRequest,
  MapMatchOption,
  SelectedLinkAnalysisRequest,
} from '../../model/AnalysisParameters';
import { RegionDto, RegionDtoProps } from '../../model/RegionDto';
import { TimeDto } from '../../model/TimeDto';
import {
  DEFAULT_SL_RADIUS_IN_KILOMETERS,
  MenuState,
} from '../../reducers/menuReducer';
import { kmToMeters } from 'logic/unit';
import { isSelectedLinkRegionSelected } from 'components/SelectedLinkPage/utils';

/**
 * Removes optional fields
 */
const prepareRegions = (regions: RegionDto[]) => {
  return regions.map((r) => {
    const props: RegionDtoProps = {
      name: r.properties.name,
      bidirectional: r.properties.bidirectional,
    };

    const preparedRegion = { ...r, properties: props };
    return preparedRegion;
  });
};

const prepareOrgs = (orgs: Set<string>): string[] =>
  orgs.size === 0 ? undefined : Array.from(orgs);

const prepareLink = (menu: MenuState) => {
  if (!isSelectedLinkRegionSelected(menu)) {
    return {
      link: {
        type: 'LineString',
        coordinates: menu.links.flatMap((it) => it.geometry.coordinates),
      },
    };
  } else {
    return {
      link: {
        type: 'Polygon',
        coordinates: menu.regions.flatMap(
          (it) => it.geometry.coordinates as any,
        ),
      },
    };
  }
};

export const prepareDataSources = (
  dataType: DataType,
  vehicleTypes: VehicleTypes,
): DataSource => {
  if (vehicleTypes === VehicleTypes.All || !vehicleTypes) {
    return dataType === DataType.All ? DataSource.ALL : DataSource.LIMITED;
  } else {
    return DataSource[`${dataType}_${vehicleTypes}`] as DataSource;
  }
};

export const dataSourceToDataType = (dataSource: DataSource): DataType => {
  if (
    dataSource === DataSource.ALL ||
    dataSource === DataSource.ALL_FLEET ||
    dataSource === DataSource.ALL_PASSENGER
  ) {
    return DataType.All;
  } else {
    return DataType.Limited;
  }
};

export const dataSourceToVehicleTypes = (
  dataSource: DataSource,
): VehicleTypes => {
  switch (dataSource) {
    case DataSource.ALL_FLEET:
    case DataSource.LIMITED_FLEET:
      return VehicleTypes.Fleet;
    case DataSource.ALL_PASSENGER:
    case DataSource.LIMITED_PASSENGER:
      return VehicleTypes.Passenger;
    case DataSource.ALL:
    case DataSource.LIMITED:
      return VehicleTypes.All;
  }
};

export const createRequest = (menu: MenuState): AnalysisRequest => {
  const { name, organizations, type } = menu;

  const timeDto: TimeDto = {
    dateRanges: menu.dateRanges,
    daysOfWeek: menu.daysOfWeek,
    timeRanges: menu.timeRanges,
    zoneId: menu.zoneId,
    timeRangeCondition: menu.timeRangeCondition,
  };

  let requestBase = {
    name,
    time: timeDto,
    dataSources: prepareDataSources(menu.dataType, menu.vehicleTypes),
    organizations: prepareOrgs(organizations),
    excludeMobile: menu.excludeMobile,
    map: {
      version: menu.mapVersion,
      type: menu.mapType,
    },
    saveDebugTraces: menu.saveDebugTraces,
  };

  if (!requestBase.excludeMobile) {
    delete requestBase.excludeMobile;
  }

  if (!requestBase.saveDebugTraces) {
    delete requestBase.saveDebugTraces;
  }

  if (type === AnalysisType.FlowMatrix) {
    let request: FlowMatrixAnalysisRequest = {
      ...requestBase,
      passMatrix: menu.passMatrix,
      regions: prepareRegions(menu.regions),
    };

    if (!request.map.version) {
      delete request.map;
    }

    if (menu.mapMatchOption !== MapMatchOption.Auto) {
      request.mapMatchOption = menu.mapMatchOption;
    }

    if (menu.tripStats) {
      request.tripStats = menu.tripStats;
    }
    return request;
  } else if (type === AnalysisType.FlowLinks) {
    let request: FlowLinksAnalysisRequest = {
      ...requestBase,
      regions: prepareRegions(menu.regions),
    };

    if (!request.map.version) {
      delete request.map;
    }

    if (menu.mapMatchOption !== MapMatchOption.Auto) {
      request.mapMatchOption = menu.mapMatchOption;
    }

    if (menu.tripStats) {
      request.tripStats = menu.tripStats;
    }
    return request;
  } else if (type === AnalysisType.SelectedLink) {
    return {
      ...requestBase,
      name,
      bufferRadiusInMeters:
        menu.bufferRadiusInKilometers === DEFAULT_SL_RADIUS_IN_KILOMETERS ||
        !menu.bufferRadiusInKilometers
          ? undefined
          : kmToMeters(menu.bufferRadiusInKilometers),
      ...prepareLink(menu),
      regionEntrancesFrcs: isSelectedLinkRegionSelected(menu)
        ? menu.entrancesFrcs
        : undefined,
    } as SelectedLinkAnalysisRequest;
  }

  throw new Error(`Unknown analysis type ${type}`);
};

export const analysisUrl = (
  type: AnalysisType,
  id: number,
  action: 'view' | 'share' = 'view',
) => {
  return `/${action}/${type === AnalysisType.SelectedLink ? 'selected-link/' : ''
    }${id}`;
};
