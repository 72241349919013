import styled from 'styled-components';
import { css } from 'styled-components';
import { Button, tombac } from 'tombac';

export const SelectedLinkSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tombac.space(4)};
`;

export const StyledInactiveButton = styled(Button)`
  pointer-events: none;
`;

const dropShadow = css`
  box-shadow: 0 1px 3px 0 var(--neutral-a24);
`;

export const ActionContainer = styled.div`
  display: flex;
  gap: ${tombac.space(1)};
  position: absolute;
  z-index: 999;
  margin: 1rem;
`;

export const ActionButtonsContainer = styled(ActionContainer)`
  ${dropShadow}
  background: white;
  border-radius: 20px;

  & > button:first-child {
    border-radius: 20;
    padding: 0;
    z-index: 1;
  }

  & > button:nth-child(2) {
    margin-left: -${tombac.space(4)};
    padding-left: ${tombac.space(5)};
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  & button {
    padding: 0 ${tombac.space(1)};
  }

  & button:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-right: ${tombac.space(3)};
  }
`;

export const ActionButton = styled(Button)`
  border-radius: 0;
`;
