import { useContext } from 'react';
import { MapContext } from 'components/Map/GlMap';

const useMap = () => {
  const map: mapboxgl.Map = useContext(MapContext);

  return map;
};

export default useMap;
