import { validateLatLng } from 'components/Map/mapUtils';
import useMap from 'hooks/useMap';
import { useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import SearchBox, {
  SearchItem,
} from 'vector-maps/dist/features/search/SearchBox';
import SearchResultIcon from 'vector-maps/dist/icons/SearchIcon';
import Marker from './Marker';

const markerIcon = ReactDOMServer.renderToStaticMarkup(<SearchResultIcon />);

export function SearchAndZoom() {
  const map = useMap();
  const [markerPos, setMarkerPos] = useState<any>(undefined);

  const handleSearch = (result: SearchItem) => {
    const bbox = result.bbox;
    const { lat, lng } = result;

    if (validateLatLng(lat, lng)) {
      const center = [lng, lat];
      setMarkerPos(center);

      if (!bbox) {
        map.setCenter(center as any);
        return;
      }

      const a = bbox.btmRightPoint;
      const b = bbox.topLeftPoint;
      map.fitBounds(
        [
          [b.lon, a.lat],
          [a.lon, b.lat],
        ],
        { padding: 200, animate: false },
      );
    }
  };

  return (
    <>
      <div
        style={{
          position: 'absolute',
          right: '80px',
          top: '5px',
          width: '350px',
        }}
      >
        <SearchBox
          onSearch={handleSearch}
          onClear={() => setMarkerPos(undefined)}
          searchOptions={{
            key: '1ncwaIygtJ0KrjH5ssohlEKUGFf7G5Dv',
            limit: 5,
          }}
        />
      </div>
      {markerPos !== undefined && (
        <Marker
          key={`search-marker`}
          lng={markerPos[0]}
          lat={markerPos[1]}
          icon={markerIcon}
          id="search-pin"
        />
      )}
    </>
  );
}
