import { timezoneFromRegions } from 'logic/time/timezoneHelpers';
import { MenuState, useMenu } from 'reducers/menuReducer';
import './EditSelectedLink.css';
import { useEffect, useState } from 'react';
import { SelectedLinkGeometrySelectorPage } from './SelectedLinkGeometrySelectorPage';
import { LinkSelectorPage } from './LinkSelectorPage';
import { RegionSelectorPage } from './RegionSelectorPage';
import { FormContainer } from 'components/UI/FormUI';

const defaultSelectorType = (menu: MenuState) => {
  if (menu.links.length > 0) {
    return SelectorType.LINK;
  } else if (menu.regions.length > 0) {
    return SelectorType.REGION;
  } else {
    return undefined;
  }
};

export const enum SelectorType {
  REGION,
  LINK,
}

export const EditSelectedLink = () => {
  const [menu, setMenu] = useMenu();
  const [selectorType, setSelectorType] = useState<SelectorType | undefined>(
    defaultSelectorType(menu),
  );

  const resetSelectorType = () => setSelectorType(undefined);

  useEffect(() => {
    const zone = timezoneFromRegions(menu.links);
    if (zone) {
      setMenu({ zoneId: zone });
    }
  }, [menu.links]);

  return (
    <FormContainer>
      {selectorType === undefined && (
        <SelectedLinkGeometrySelectorPage setSelectorType={setSelectorType} />
      )}
      {selectorType === SelectorType.LINK && (
        <LinkSelectorPage resetSelectorType={resetSelectorType} />
      )}
      {selectorType === SelectorType.REGION && (
        <RegionSelectorPage resetSelectorType={resetSelectorType} />
      )}
    </FormContainer>
  );
};
