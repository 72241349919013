// @ts-check
import { saveAs } from 'file-saver';
import mapboxgl from 'mapbox-gl';
import { PureComponent } from 'react';
import { Button, PropsWithPropStyling } from 'tombac';
import { CameraIcon } from 'tombac-icons';

export type MapScreenshotProps = PropsWithPropStyling<{
  map: mapboxgl.Map;
}>;

class MapScreenshot extends PureComponent<MapScreenshotProps> {
  state = {
    loading: false,
  };

  takeScreenshot = () => {
    this.setState({ loading: true });
    const map = this.props.map;
    const zoom = map.getZoom();
    const center = map.getCenter();
    const bearing = map.getBearing();
    const pitch = map.getPitch();
    const style = map.getStyle();
    const width = map.getCanvas().style.width;
    const height = map.getCanvas().style.height;

    const dpi = 250;
    const actualPixelRatio = window.devicePixelRatio;
    Object.defineProperty(window, 'devicePixelRatio', {
      get: function () {
        return dpi / 96;
      },
    });

    const hidden = document.createElement('div');
    hidden.style.overflow = 'hidden';
    hidden.style.height = '0';
    hidden.style.width = '0';
    hidden.style.position = 'fixed';
    document.body.appendChild(hidden);
    const container = document.createElement('div');
    container.style.width = width;
    container.style.height = height;
    hidden.appendChild(container);

    // Render map
    const renderMap = new mapboxgl.Map({
      container: container,
      center: center,
      zoom: zoom,
      style: style,
      bearing: bearing,
      pitch: pitch,
      interactive: false,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });
    renderMap.once('load', () => {
      setTimeout(() => {
        const canvas = renderMap.getCanvas();
        canvas.toBlob(
          (blob) => {
            saveAs(blob, 'map.jpeg');
            renderMap.remove();
            hidden.parentNode.removeChild(hidden);
            Object.defineProperty(window, 'devicePixelRatio', {
              get: function () {
                return actualPixelRatio;
              },
            });
            this.setState({ loading: false });
          },
          'image/jpeg',
          0.9,
        );
      }, 500);
    });
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { map, ...stylingProps } = this.props;
    const { loading } = this.state;
    return (
      <Button
        {...stylingProps}
        busy={loading}
        onClick={this.takeScreenshot}
        prepend={<CameraIcon />}
        size="s"
      >
        Screenshot
      </Button>
    );
  }
}

export default MapScreenshot;
