import { SimpleTooltip } from 'components/AnalysisTable/SimpleTooltip';
import { ZoomToIcon } from 'tombac-icons';
import './ZoomInButton.css';
import classNames from 'classnames';
import { Text } from 'tombac';

interface Props {
  onClick: () => void;
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  type: 'regions' | 'link';
}

const ZoomInButton = (props: Props) => {
  return (
    <div
      className={classNames('ZoomInButton', {
        ['ZoomInButton--topRight']: props.position === 'top-right',
        ['ZoomInButton--bottomRight']: props.position === 'bottom-right',
        ['ZoomInButton--bottomLeft']: props.position === 'bottom-left',
      })}
      onClick={props.onClick}
    >
      <SimpleTooltip
        content={
          <Text $color="#ffffff" style={{ width: 'max-content' }}>
            Pan to {props.type}
          </Text>
        }
        placement="auto"
      >
        <ZoomToIcon />
      </SimpleTooltip>
    </div>
  );
};

export default ZoomInButton;
