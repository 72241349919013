import { AddPolygonIcon } from 'tombac-icons';
import {
  ActionButton,
  ActionButtonsContainer,
  StyledInactiveButton,
} from './SelectedLink.style';
import { RegionDto } from 'model/RegionDto';

interface Props {
  resetSelectorType: VoidFunction;
  region: RegionDto;
  deleteAllClickHandle: VoidFunction;
}

export const SelectedLinkRegionSelectorActions = ({
  resetSelectorType,
  region,
  deleteAllClickHandle,
}: Props) => {
  return (
    <ActionButtonsContainer>
      <StyledInactiveButton variant="tertiary">
        <AddPolygonIcon />
      </StyledInactiveButton>
      {!region ? (
        <ActionButton variant="flat" onClick={resetSelectorType}>
          Cancel
        </ActionButton>
      ) : (
        <ActionButton variant="flat" onClick={deleteAllClickHandle}>
          Delete
        </ActionButton>
      )}
    </ActionButtonsContainer>
  );
};
