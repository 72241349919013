import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { useRouteMatch } from 'react-router-dom';
import { createVTypeButton } from '../createVTypeButton';

import { AnalysisType } from 'model/AnalysisDto';
import { Box } from 'tombac';
import { AsideSection } from 'components/UI/UI';
import { ReactComponent as Map } from '../map.svg';
import { ReactComponent as Matrix } from '../matrix.svg';
import { ReactComponent as Sankey } from '../sankey.svg';

const odResults = [
  { name: 'Map Flows', path: 'map-flows', icon: <Map /> },
  {
    name: 'Matrix',
    path: 'colored-matrix',
    icon: <Matrix />,
    dataTest: 'button-colored-matrix',
  },
  { name: 'Sankey Diagram', path: 'sankey', icon: <Sankey /> },
  { name: 'Spatial Sankey', path: 'spatial-sankey', icon: <Map /> },
  { name: 'Flows Explorer', path: 'mfp', icon: <Map /> },
];

export const Results = () => {
  const match = useRouteMatch();
  const { analysis } = useAnalysisContext();
  const ResultButton = createVTypeButton(match.url);
  return (
    <AsideSection disableBorder title="Results" canHide={false}>
      {analysis.info.type === AnalysisType.FlowMatrix ? (
        <Box>
          {odResults.map(({ name, path, icon, dataTest }) => (
            <ResultButton
              key={path}
              name={name}
              path={path}
              icon={icon}
              data-test={dataTest}
            />
          ))}
        </Box>
      ) : (
        <Box>
          <ResultButton
            name="View Results"
            path="selected-link"
            icon={<Map size={18} />}
          />
        </Box>
      )}
    </AsideSection>
  );
};
