import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import { Provider } from 'react-redux';
import { TombacApp } from 'tombac';
import { getReduxStore } from '../../..';
import './Popup.css';
import { withMap } from './withMap';

class Popup extends Component {
  static propTypes = {
    children: PropTypes.any,
    position: PropTypes.array,
    offset: PropTypes.array,
    onClose: PropTypes.func,
  };

  componentDidMount() {
    this.createPopup();
  }

  createPopup() {
    const mapbox = this.props.map;
    const { position, offset, className, maxWidth = 240 } = this.props;
    this.popup = new mapboxgl.Popup({
      anchor: 'bottom',
      offset,
      className,
      maxWidth,
    })
      .setLngLat(position)
      .setDOMContent(this.childrenToDom())
      .addTo(mapbox);

    this.popup._closeButton.addEventListener('click', this.handleCloseClick);
  }

  handleCloseClick = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  childrenToDom() {
    this.div = document.createElement('div');
    ReactDOM.render(
      <TombacApp>
        <Provider store={getReduxStore()}>{this.props.children}</Provider>
      </TombacApp>,
      this.div,
    );
    return this.div;
  }

  componentDidUpdate() {
    this.popup.setDOMContent(this.childrenToDom());
  }

  componentWillUnmount() {
    const mapbox = this.props.map;
    if (mapbox) {
      this.popup._closeButton.removeEventListener(
        'click',
        this.handleCloseClick,
      );
      this.popup.remove();
      this.div.remove();
    }
  }

  render() {
    return null;
  }
}
export default withMap(Popup);
