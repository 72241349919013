import {
  DEFAULT_SL_AS_REGION_ENTRANCES_FRC,
  useMenu,
} from 'reducers/menuReducer';
import { Progress } from './Progress';
import { SelectedLinkSettingsContainer } from './SelectedLink.style';
import { FrcSelector } from './FrcSelector';
import { SimpleTooltip } from 'components/AnalysisTable/SimpleTooltip';
import { SliderInput } from 'components/SelectedLinkPage/SliderInput';
import { Label, Checkbox, Caption, tombac, Box } from 'tombac';
import { HelpIcon } from 'tombac-icons';
import { LinkRadiusContainer, LinkRadiusLabel } from './LinkSettings';
import { useEffect } from 'react';

export const MAX_SELECTED_LINK_REGION_AREA = 3;

const MIN_REGION_RADIUS_IN_KILOMETERS = 5;
const MAX_REGION_RADIUS_IN_KILOMETERS = 10;

interface Props {
  regionArea: number;
  showRadius: boolean;
  setShowRadius: (showRadius: boolean) => void;
}

export const SelectedLinkRegionSettings = ({
  regionArea,
  showRadius,
  setShowRadius,
}: Props) => {
  const [menu, setMenu] = useMenu();

  const setEntrancesFrcs = (entrancesFrcs: number[]) => {
    setMenu({ entrancesFrcs });
  };

  useEffect(() => {
    if (menu.bufferRadiusInKilometers > MAX_REGION_RADIUS_IN_KILOMETERS) {
      setMenu({ bufferRadiusInKilometers: MAX_REGION_RADIUS_IN_KILOMETERS });
    }
  }, [menu.bufferRadiusInKilometers, setMenu]);

  return (
    <SelectedLinkSettingsContainer>
      <Progress
        label="Region area"
        value={+regionArea.toFixed(2)}
        max={MAX_SELECTED_LINK_REGION_AREA}
        unit="km²"
      />

      <LinkRadiusContainer>
        <LinkRadiusLabel>
          <Label
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              alignItems: 'center',
            }}
          >
            Radius
            <SimpleTooltip content="The radius of trips from the link that can be analyzed.">
              <HelpIcon />
            </SimpleTooltip>
          </Label>
          <Checkbox
            label={
              <Caption $color={tombac.color('neutral', 700)}>
                Show radius
              </Caption>
            }
            size="xs"
            variant="toggle"
            checked={showRadius}
            labelPlacement="before"
            onChange={() => setShowRadius(!showRadius)}
          />
        </LinkRadiusLabel>{' '}
        <Box>
          <SliderInput
            min={MIN_REGION_RADIUS_IN_KILOMETERS}
            max={MAX_REGION_RADIUS_IN_KILOMETERS}
            value={menu.bufferRadiusInKilometers}
            unit="km"
            onChange={(v) =>
              setMenu({ bufferRadiusInKilometers: Math.floor(v) })
            }
            onAfterChange={(v) => {
              setMenu({
                bufferRadiusInKilometers: Math.floor(v),
              });
            }}
          />
        </Box>
      </LinkRadiusContainer>
      <FrcSelector
        label="Select exits/entrances by road classes"
        frcsValues={DEFAULT_SL_AS_REGION_ENTRANCES_FRC}
        selectedFrcs={menu.entrancesFrcs}
        setSelectedFrcs={setEntrancesFrcs}
      />
    </SelectedLinkSettingsContainer>
  );
};
