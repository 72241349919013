import { Position, circle } from '@turf/turf';
import { Lines, Source } from './MapboxComponents';
import useMap from 'hooks/useMap';
import { useEffect, useMemo } from 'react';

interface Props {
  center: Position;
  radiusInKm: number;
  showRadius: boolean;
}

export const LinkRadiusLayer = ({ center, radiusInKm, showRadius }: Props) => {
  const map = useMap();

  const radiusFeatureCollection = useMemo(() => {
    return circle([center[0], center[1]], radiusInKm, { steps: 128 });
  }, [center, radiusInKm]);

  useEffect(() => {
    const handleZoom = () => {
      const circleCoordinates = radiusFeatureCollection.geometry.coordinates[0];
      map.fitBounds(
        [
          [circleCoordinates[0][0], circleCoordinates[0][1]],
          [circleCoordinates[64][0], circleCoordinates[64][1]],
        ],
        { padding: 100, animate: true },
      );
    };

    if (showRadius) {
      handleZoom();
    }
  }, [map, radiusInKm, showRadius]);

  return (
    <>
      <Source id="link-radius" data={radiusFeatureCollection}>
        <Lines
          id="link-radius"
          line-color="rgba(0, 0, 0, 0.16)"
          line-opacity={0.5}
          line-width={5}
        />
      </Source>
    </>
  );
};
