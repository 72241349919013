import { Feature, LineString } from '@turf/helpers';
import { center, length, lineString } from '@turf/turf';
import { centerOnRegions, queryFeatures } from 'components/Map/mapUtils';
import useMap from 'hooks/useMap';
import { useMapEvent } from 'hooks/useMapEvent';
import * as React from 'react';
import { useMenu } from 'reducers/menuReducer';
import styled from 'styled-components';
import { tombac } from 'tombac';
import { AddIcon, DeleteIcon, InfoIcon } from 'tombac-icons';
import arrowTriangle from './assets/arrow-triangle.svg';
import { CursorHint } from './CursorHint';
import './EditSelectedLink.css';
import { IconImage, Lines, Source, Symbols } from './MapboxComponents';
import { MapSegmentsLayer } from './MapSegmentsLayer';
import { LinkRadiusLayer } from './LinkRadiusLayer';
import { LINK_MAX_LENGTH_IN_METERS } from './LinkSettings';
import { LinkSelectorActions } from './LinkSelectorActions';
import { useSegments } from 'hooks/useSegments';
import { useMapZoomActive } from 'hooks/useMapZoom';

const useLength = (features: Feature[]) => {
  return React.useMemo(() => {
    return features
      .map((it) => length(it, { units: 'meters' }))
      .reduce((a, b) => a + b, 0);
  }, [features]);
};

interface Hover {
  segmentId: string;
  role: 'add' | 'remove';
  feature: Feature<LineString>;
}
interface SegmentSelectorProps {
  selected: Feature<LineString>[];
  setSelected: (selected: Feature<LineString>[]) => void;
  maxLength: number;
  segments: Feature<LineString>[];
}

function SegmentsSelector({
  selected,
  setSelected,
  maxLength,
  segments,
}: SegmentSelectorProps) {
  const [menu, setMenu] = useMenu();

  const getFullFeature = (id: string): Feature<LineString> | undefined =>
    segments.find((it) => it.properties.id === id);

  const [hover, _setHover] = React.useState<Hover | undefined>(undefined);
  const clearHover = () => _setHover(undefined);
  const setHover = (
    role: 'add' | 'remove',
    feature: Feature<LineString>,
    id: string,
  ) => {
    _setHover({
      role,
      segmentId: id,
      feature: {
        ...feature,
        properties: {
          ...feature.properties,
          'line-color': role === 'add' ? '#bcddf5' : '#ffaaa8',
          'line-width': role === 'add' ? 12 : 24,
        },
      },
    });
  };

  React.useEffect(() => {
    const getFullFeatureFromPartialId = (id: string) => {
      return segments.find(
        (it) =>
          it.properties.id === id || it.properties.mergedFrom.includes(id),
      );
    };
    const getFeatures = async () => {
      const ids = (selected[0].properties as any).edgeIds;

      const fullFeatures: any[] = ids
        .map((it) => getFullFeatureFromPartialId(it))
        .filter(Boolean);

      setSelected([...new Set(fullFeatures)]);
    };

    if (
      segments.length &&
      selected.length === 1 &&
      (selected[0].properties as any).edgeIds !== undefined
    ) {
      getFeatures();
    }
  }, [selected, menu.mapType, menu.mapVersion, segments]);

  const selectedLength = useLength(selected);
  const limitReached = selectedLength >= maxLength;

  // Sync to redux
  React.useEffect(() => {
    setMenu({
      ...menu,
      links: selected.map((it) => it),
    });
  }, [selected]);

  // Zoom
  const map = useMap();
  React.useEffect(() => {
    if (menu.links.length > 0) {
      centerOnRegions(map, menu.links);
    }
  }, []);

  const {
    outboundIds,
    availableSegments,
    availableSegmentIds,
  } = React.useMemo(() => {
    if (selected.length === 0)
      return {
        inboundIds: [],
        outboundIds: [],
        availableSegments: [],
      };

    const first = selected[0];
    const last = selected[selected.length - 1];

    const outboundIds =
      getFullFeature(last.properties.id)?.properties?.outbound ?? [];
    const inboundIds =
      getFullFeature(first.properties.id)?.properties?.inbound ?? [];

    const availableSegments = [...outboundIds, ...inboundIds]
      .map((id) => getFullFeature(id))
      .filter(Boolean);

    const availableSegmentIds = availableSegments.map((it) => it.properties.id);

    return {
      outboundIds,
      inboundIds,
      availableSegments,
      availableSegmentIds,
    };
  }, [selected, segments]);

  useMapEvent('mousemove', (e) => {
    let featuresIds = queryFeatures(e, ['all-segments']).map(
      (it) => it.feature.properties.id,
    );

    const selectedIds = selected.map((it) => it.properties.id);

    const isAvailable = (id) => availableSegmentIds.includes(id);
    const isSelected = (id) =>
      id === selectedIds[0] || id === selectedIds[selectedIds.length - 1];

    let hoverRole: 'add' | 'remove' = 'add';
    const hoveredFeatureId = featuresIds.find((id) => {
      // Hover any segment at the beginning
      if (selected.length === 0) return true;
      // Hover available segment to add
      if (isAvailable(id)) return true;
      // Hover selected segment to remove
      if (isSelected(id)) {
        hoverRole = 'remove';
        return true;
      }
    });

    map.getCanvas().style.cursor =
      hoveredFeatureId !== undefined ? 'pointer' : 'unset';

    if (hoveredFeatureId === undefined) {
      clearHover();
      return;
    }

    if (limitReached && hoverRole === 'add') return;

    setHover(hoverRole, getFullFeature(hoveredFeatureId), hoveredFeatureId);
  });

  React.useEffect(() => {
    setMenu({ showRoadNetworkSwitch: false });
    const handleMouseLeave = () => {
      clearHover();
    };

    map.getCanvas().addEventListener('mouseleave', handleMouseLeave);
    return () => {
      map.getCanvas().removeEventListener('mouseleave', handleMouseLeave);
      setMenu({ showRoadNetworkSwitch: true });
    };
  }, []);

  useMapEvent('click', () => {
    if (hover === undefined || (hover.role === 'add' && limitReached)) return;

    if (hover.role === 'add') {
      setSelected(
        outboundIds.includes(hover.segmentId)
          ? [...selected, hover.feature]
          : [hover.feature, ...selected],
      );
    } else {
      setSelected(
        selected.filter((it) => it.properties.id !== hover.segmentId),
      );
    }
    setHover(
      hover.role === 'add' ? 'remove' : 'add',
      hover.feature,
      hover.segmentId,
    );
  });

  return (
    <>
      <MapSegmentsLayer segments={segments} />

      {hover !== undefined && (
        <CursorHint>
          {hover.role === 'add' ? (
            <>
              <AddIcon color="#61ade0" /> Add segment
            </>
          ) : (
            <>
              <DeleteIcon color="#ff7a75" /> Remove segment
            </>
          )}
        </CursorHint>
      )}

      <Source
        id="available"
        data={limitReached ? undefined : availableSegments}
      >
        <Lines line-cap="round" line-color={'#eee'} line-width={12} />
        <Symbols
          icon-image="arrow-revers"
          symbol-avoid-edges
          symbol-placement="line"
          symbol-spacing={10}
        />
      </Source>

      <Source data={hover?.feature}>
        <Lines
          line-cap="round"
          line-color={['get', 'line-color']}
          line-width={['get', 'line-width']}
        />
      </Source>

      <Source data={selected}>
        <IconImage id="arrow-triangle" src={arrowTriangle} />
        <Lines line-cap="round" line-color="rgb(192,226,253)" line-width={18} />
        <Lines
          line-cap="round"
          line-color="rgba(42,145,213,1)"
          line-width={12}
        />
        <Symbols
          icon-image="arrow-triangle"
          icon-size={0.8}
          symbol-avoid-edges
          symbol-placement="line"
          symbol-spacing={30}
        />
      </Source>
    </>
  );
}

const MapHint = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 12px;
  z-index: 1;
  margin-top: 15px;
  font-family: ${tombac.altFontFamily};
  font-size: 12px;
  font-weight: 500;
`;

function SegmentsHint() {
  const segmentsAreVisible = useMapZoomActive(13);
  return segmentsAreVisible ? null : (
    <MapHint>
      <InfoIcon style={{ marginRight: '5px' }} /> Zoom in to see road segments
    </MapHint>
  );
}

interface Props {
  showRadius: boolean;
  resetSelectorType: VoidFunction;
  bufferRadiusInKilometers: number;
  setBufferRadiusInKilometers: (bufferRadiusInKilometers: number) => void;
}

export function LinkSelector({
  showRadius,
  resetSelectorType,
  bufferRadiusInKilometers,
  setBufferRadiusInKilometers,
}: Props) {
  const [menu] = useMenu();
  const [selected, setSelected] = React.useState<Feature<LineString>[]>([
    ...menu.links,
  ]);
  const segments = useSegments(menu.mapVersion, menu.mapType);

  const linkCenterPosition = () => {
    const linkGeometry = menu.links
      .map((it) => it.geometry.coordinates)
      .flat(1);
    return center(lineString(linkGeometry)).geometry.coordinates;
  };

  const opposite = React.useMemo(() => {
    if (selected.length !== 1) return;

    // Try to find opposite direction segment
    const selectedFeature = selected[0];

    const opposite = segments.find((it) => {
      const itCoords = it.geometry.coordinates;
      const coords = selectedFeature.geometry.coordinates;

      return (
        itCoords[0][0] === coords[coords.length - 1][0] &&
        itCoords[0][1] === coords[coords.length - 1][1] &&
        itCoords[itCoords.length - 1][0] === coords[0][0] &&
        itCoords[itCoords.length - 1][1] === coords[0][1]
      );
    });

    return opposite;
  }, [selected]);

  const handleResetSelectorType = React.useCallback(() => {
    resetSelectorType();
    setBufferRadiusInKilometers(0);
  }, [resetSelectorType, setBufferRadiusInKilometers]);

  return (
    <>
      <SegmentsHint />
      <LinkSelectorActions
        selected={selected}
        opposite={opposite}
        resetSelectorType={handleResetSelectorType}
        deleteAllClickHandle={() => setSelected([])}
        oppositeClickHandle={() => setSelected([opposite])}
      />
      <SegmentsSelector
        segments={segments}
        selected={selected}
        setSelected={setSelected}
        maxLength={LINK_MAX_LENGTH_IN_METERS}
      />
      {menu.links.length > 0 && showRadius && (
        <LinkRadiusLayer
          radiusInKm={bufferRadiusInKilometers}
          center={linkCenterPosition()}
          showRadius={showRadius}
        />
      )}
    </>
  );
}
