import { center, Feature, featureCollection, Point } from '@turf/turf';
import LimitsApi, { LimitsWithUsage } from 'api/LimitsApi';
import DisplayRegions from 'components/Map/Layers/DisplayRegions';
import { useMapZoomActive } from 'hooks/useMapZoom';
import { useSegments } from 'hooks/useSegments';
import { useSegmentsIntersectRegion } from 'hooks/useSegmentsIntersectRegion';
import { RegionDto } from 'model/RegionDto';
import { useEffect, useMemo, useState } from 'react';
import { useMenu } from 'reducers/menuReducer';
import PolygonSelector from '../RegionSelection/PolygonSelector/PolygonSelector';
import { RegionErrorPopup } from '../RegionSelection/RegionErrorPopup';
import { getCenter, getDisplayErrorModal } from '../RegionSelection/utils';
import {
  validateSelectedLinkRegion,
  ValidationStatus,
} from '../RegionSelection/validation';
import { LinkRadiusLayer } from './LinkRadiusLayer';
import { MapSegmentsLayer } from './MapSegmentsLayer';
import { RegionIntersectionFeature } from './RegionIntersectionFeature';
import { SelectedLinkRegionSelectorActions } from './SelectedLinkRegionSelectorActions';

interface Props {
  resetSelectorType: VoidFunction;
  setIntersectionsPointsNumber: (number: number) => void;
  showRadius: boolean;
  bufferRadiusInKilometers: number;
}

const applySelectedLinkRegion = (
  region: RegionDto,
  limits: LimitsWithUsage,
) => {
  const newProperties: any = { ...region.properties };

  if (region.properties.validationResult === undefined) {
    newProperties.validationResult = validateSelectedLinkRegion(
      region,
      limits.limits.allowedArea,
    );
  }
  return { ...region, properties: newProperties };
};

export const SelectedLinkRegionSelector = ({
  resetSelectorType,
  setIntersectionsPointsNumber,
  showRadius,
  bufferRadiusInKilometers,
}: Props) => {
  const [menu, setMenu] = useMenu();
  const [region, setRegion] = useState(menu.regions[0] ?? undefined);
  const shouldDisplayErrorPopup = useMemo(
    () => getDisplayErrorModal([region], 0),
    [region],
  );
  const { intersectionPoints, loading, error } = useSegmentsIntersectRegion({
    region,
  });
  const segments = useSegments(menu.mapVersion, menu.mapType);
  const limits = LimitsApi.use();

  const linkCenterPosition = () => {
    const geojson = featureCollection(menu.regions);
    const regionsCenter = center(geojson);
    return regionsCenter.geometry.coordinates;
  };

  useEffect(() => {
    setMenu({ regions: region ? [region] : [] });
  }, [region]);

  useEffect(() => {
    setIntersectionsPointsNumber(intersectionPoints?.length ?? 0);
  }, [intersectionPoints]);

  const addRegion = async (regionsToAdd: RegionDto[]) =>
    setRegion(applySelectedLinkRegion(regionsToAdd[0], limits));

  const clearRegions = () => setRegion(undefined);

  const showSegments = useMapZoomActive(13) && menu.showRoadNetwork;

  const canShowIntersection =
    menu.entrancesFrcs?.length > 0 &&
    region?.properties?.validationResult?.status === ValidationStatus.OK &&
    !loading &&
    !error;

  return (
    <>
      {menu.regions.length > 0 && showRadius && (
        <LinkRadiusLayer
          radiusInKm={bufferRadiusInKilometers}
          center={linkCenterPosition()}
          showRadius={showRadius}
        />
      )}
      {showSegments && (
        <MapSegmentsLayer
          beforeId={canShowIntersection ? 'intersections-points' : undefined}
          segments={segments}
        />
      )}

      <SelectedLinkRegionSelectorActions
        resetSelectorType={resetSelectorType}
        region={region}
        deleteAllClickHandle={clearRegions}
      />
      {!region && <PolygonSelector onSelect={addRegion} />}
      <DisplayRegions
        dtoRegions={menu.regions}
        layerId="creating-regions"
        centerOnRegions
      />
      {canShowIntersection && (
        <RegionIntersectionFeature
          intersections={intersectionPoints as Feature<Point>[]}
        />
      )}
      {shouldDisplayErrorPopup && (
        <RegionErrorPopup
          coords={getCenter(region)}
          message={region.properties.validationResult.message}
        />
      )}
    </>
  );
};
