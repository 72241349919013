import { SelectedLinkFeature } from 'components/AnalysisNew/SelectedLink/SelectedLinkFeature';
import Title from 'components/Layout/Title';
import { useMemo, useState } from 'react';
import { Checkbox, Modal } from 'tombac';
import GlMap from '../../Map/GlMap';
import DisplayRegions from '../../Map/Layers/DisplayRegions';
import { MapControls, ZoomInButton } from '../../UI/UI';
import { useAnalysisContext } from '../AnalysisViewPage';
import ViewPageContent from '../ViewPageContent/ViewPageContent';
import './AnalysisMap.css';
import { AnalysisMapSidebar } from './AnalysisMapSidebar/AnalysisMapSidebar';
import { AnalysisType } from 'model/AnalysisDto';
import { useCenterRegions } from './useCenterRegions';
import {
  AnalysisMapModalActions,
  AnalysisMapModalState,
} from './AnalysisMapSidebar/analysisMap.types';
import { AnalysisMapModalContent } from './AnalysisMapSidebar/AnalysisMapModalContent';
import { isSelectedLinkRegion } from 'components/SelectedLinkPage/utils';
import { centerOnRegions } from 'components/Map/mapUtils';

function AnalysisMap() {
  const { analysis } = useAnalysisContext();
  const [regionNames, setRegionNames] = useState(true);
  const [modalState, setModalState] = useState<AnalysisMapModalState>('CLOSED');
  const mapbox = useCenterRegions();

  const modalActions = useMemo<AnalysisMapModalActions>(
    () => ({
      closeModal: () => setModalState('CLOSED'),
      openRenamingModal: () => setModalState('RENAMING'),
      openRenamingRegionsModal: () => setModalState('RENAMING_REGIONS'),
      openCancelModal: () => setModalState('CANCEL'),
      openSummaryWithActionsModal: () => setModalState('SUMMARY_WITH_ACTIONS'),
      openSummaryModal: () => setModalState('SUMMARY_ONLY'),
    }),
    [],
  );

  const { type, id } = analysis.info;
  const regions = analysis.regions;

  const isShared = location.href.includes('/share/');

  return (
    <>
      <Title>Analysis</Title>{' '}
      <ViewPageContent>
        <AnalysisMapSidebar isShared={isShared} modalActions={modalActions} />
        <GlMap
          mapStyle="basic_main"
          mapboxRef={(c: any) => (mapbox.current = c)}
        >
          {regions.length > 0 && (
            <ZoomInButton
              onClick={() => centerOnRegions(mapbox.current, regions)}
              position="top-left"
              type={type === AnalysisType.SelectedLink ? 'link' : 'regions'}
            />
          )}
          {type === AnalysisType.FlowMatrix && (
            <MapControls title="Controls">
              <Checkbox
                checked={regionNames}
                label="Region names"
                onChange={(e) => setRegionNames(e.target.checked)}
              />
            </MapControls>
          )}
          {type === AnalysisType.SelectedLink &&
            (isSelectedLinkRegion(analysis) ? (
              <DisplayRegions dtoRegions={regions} />
            ) : (
              <SelectedLinkFeature links={regions} />
            ))}
          {type === AnalysisType.FlowMatrix && (
            <DisplayRegions
              hash={'' + id}
              dtoRegions={regions}
              regionNames={regionNames}
            />
          )}
        </GlMap>
      </ViewPageContent>
      <Modal
        isOpen={modalState !== 'CLOSED'}
        onRequestClose={modalActions.closeModal}
        className={`AnalysisMap__Modal ${modalState} `}
      >
        <AnalysisMapModalContent
          modalActions={modalActions}
          modalState={modalState}
        />
      </Modal>
    </>
  );
}

export default AnalysisMap;
