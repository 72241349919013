import { Text } from 'tombac';
import { SpinnerIcon } from 'tombac-icons';

export const MapsLoading = () => {
  return (
    <>
      <SpinnerIcon spin />
      <Text $ml="1sp" size="s">
        Loading maps, please wait...
      </Text>
    </>
  );
};
