import { AnalysisType } from 'model/AnalysisDto';
import { fetchApi, retriable } from './api';
export interface JobStats {
  regionsArea: number;
  regionsCount: number;
  roadCoverage: number;
}

const path = `/rest/analysis`;

const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));

const getStats = async (type: AnalysisType, analysisId: number) => {
  return await retriable(20, async () => {
    const typeId = {
      [AnalysisType.FlowMatrix]: 'flowmatrix',
      [AnalysisType.SelectedLink]: 'selected-link',
    }[type];

    const stats = await fetchApi<JobStats>(
      `${path}/${typeId}/${analysisId}/stats`,
      { method: 'GET' },
      `json`,
    );
    if (stats.roadCoverage === null) {
      await sleep(1000);
      throw new Error('Pricing coverage is being calculated');
    }
    return stats;
  });
};

const JobStatsApi = { getStats };

export default JobStatsApi;
