import { withMap } from 'components/Map/Layers/withMap';
import _debounce from 'lodash/debounce';
import _throttle from 'lodash/throttle';
import { Marker as MapboxMarker, MarkerOptions } from 'mapbox-gl';
import React, { Component } from 'react';

const createElement = (
  icon: boolean,
  iconWidth: number,
  iconHeight: number,
  id: string,
) => {
  const el = document.createElement('div');
  el.style.width = `${iconWidth}px`;
  el.style.height = `${iconHeight}px`;
  el.innerHTML = icon.toString();
  el.id = id;
  return el;
};

export interface MarkerProps {
  icon?: any;
  iconWidth?: number;
  iconHeight?: number;
  lng: number;
  lat: number;
  markerOptions?: MarkerOptions;
  map?: any;
  onClick?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
  onPositionChange?: any;
  onDragStart?: any;
  onDrag?: any;
  onContextMenu?: any;
  onDoubleClick?: any;
  id?: any;
  data?: any;
}
class Marker extends Component<MarkerProps, any> {
  marker: MapboxMarker;
  markerRemoved: boolean;
  constructor(props: MarkerProps | Readonly<MarkerProps>) {
    super(props);
    this.mouseUp = this.mouseUp.bind(this);
    this.onDrag = _throttle(this.onDrag.bind(this), 100);
    this.onDragStart = this.onDragStart.bind(this);
    this.handleMouseEnter = _debounce(this.handleMouseEnter.bind(this));
    this.handleMouseLeave = _debounce(this.handleMouseEnter.bind(this));

    this.handleClick = this.handleClick.bind(this);
    this.handleContextMenu = this.handleContextMenu.bind(this);
    this.handleDoubleClick = this.handleDoubleClick.bind(this);
  }

  componentDidMount() {
    if (this.props.map) {
      this.addMarkerToMap();
    }
  }
  componentDidUpdate(prevProps: MarkerProps) {
    if (this.marker) {
      const { id, icon, iconWidth, iconHeight, lng, lat } = this.props;
      this.marker.setLngLat([lng, lat]);
      if (icon !== prevProps.icon) {
        const el = this.marker.getElement();
        el.id = id.toString();
        el.style.width = `${iconWidth}px`;
        el.style.height = `${iconHeight}px`;
        el.innerHTML = createElement(icon, iconWidth, iconHeight, id).innerHTML;
      }
    }
  }

  componentWillUnmount() {
    if (this.marker) {
      this.marker.getElement().addEventListener('click', this.handleClick);
      this.marker.remove();
    }
  }

  addMarkerToMap() {
    const {
      icon,
      iconWidth,
      iconHeight,
      lng,
      lat,
      markerOptions,
      map,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onContextMenu,
      onDoubleClick,
      id,
    } = this.props;

    const options = { ...markerOptions };
    if (icon) {
      options.element = createElement(icon, iconWidth, iconHeight, id);
    }
    this.marker = new MapboxMarker(options);
    this.marker.setLngLat([lng, lat]).addTo(map);
    this.marker.on('dragend', this.mouseUp);
    this.marker.on('drag', this.onDrag);
    this.marker.on('dragstart', this.onDragStart);
    if (onMouseEnter) {
      this.marker
        .getElement()
        .addEventListener('mouseenter', this.handleMouseEnter);
    }
    if (onMouseLeave) {
      this.marker
        .getElement()
        .addEventListener('mouseleave', this.handleMouseLeave);
    }
    if (onClick) {
      this.marker.getElement().addEventListener('click', this.handleClick);
    }
    if (onContextMenu) {
      this.marker
        .getElement()
        .addEventListener('contextmenu', this.handleContextMenu);
    }
    if (onDoubleClick) {
      this.marker
        .getElement()
        .addEventListener('dblclick', this.handleDoubleClick);
    }
    this.markerRemoved = false;
  }
  handleClick(e: any) {
    const { onClick, id, data } = this.props;
    onClick({ originalEvent: e, id, data });
  }
  handleContextMenu(e: any) {
    const { onContextMenu, id, data } = this.props;
    onContextMenu({ originalEvent: e, id, data });
  }
  handleDoubleClick(e: any) {
    const { onDoubleClick, id, data } = this.props;
    onDoubleClick({ originalEvent: e, id, data });
  }
  handleMouseEnter(e: any) {
    const { onMouseEnter, data, id } = this.props;

    onMouseEnter({ originalEvent: e, id, data });
  }
  handleMouseLeave(e: any) {
    const { onMouseLeave, data, id } = this.props;

    onMouseLeave({ originalEvent: e, id, data });
  }
  mouseUp() {
    const { onPositionChange, id } = this.props;
    if (onPositionChange) {
      const { lng, lat } = this.marker.getLngLat();
      onPositionChange({ id, lng, lat });
    }
  }
  onDrag() {
    const { onDrag } = this.props;
    if (onDrag) {
      const { lng, lat } = this.marker.getLngLat();
      onDrag({ id: this.props.id, lng, lat });
    }
  }
  onDragStart() {
    const { onDragStart } = this.props;
    if (onDragStart) {
      const { lng, lat } = this.marker.getLngLat();
      onDragStart({ id: this.props.id, lng, lat });
    }
  }

  /* eslint-disable class-methods-use-this */
  render(): any {
    return null;
  }
  /* eslint-enable class-methods-use-this */
}

export default withMap(React.memo(Marker));
