import Popup from 'components/Map/Layers/Popup';
import './RegionErrorPopup.css';

interface Props {
  coords: number[];
  message: string;
}

export function RegionErrorPopup(props: Props) {
  return (
    <Popup
      className="RegionErrorPopup"
      position={props.coords}
      offset={[0, -10]}
      maxWidth={500}
    >
      <div>
        <div>{props.message}</div>
      </div>
    </Popup>
  );
}
