import { FormGroup } from 'tombac';
import { EmptyPlaceholder } from 'components/UI/EmptyPlaceholder';
import { useMenu } from 'reducers/menuReducer';
import { useMemo, useState } from 'react';
import { regionOutside } from '../RegionSelection/validation';
import LimitsApi from 'api/LimitsApi';
import { useValidation } from 'hooks/useValidation';
import { LINK_MAX_LENGTH_IN_METERS, LinkSettings } from './LinkSettings';
import { Feature } from '@turf/turf';
import { length } from '@turf/turf';
import {
  nameRequirements,
  selectedLinkRequirements,
} from '../Requirements/requirements';
import { useRoadCoverage } from 'hooks/useRoadCoverage';
import { AnalysisType } from 'model/AnalysisDto';
import RoadCoverage from '../RoadCoverage/RoadCoverage';
import { EditSelectedLinkPageTemplate } from './EditSelectedLinkEditTemplate';
import { MapControl } from 'components/UI/FormUI';
import MultipleMapSelector from '../RegionSelection/MapVersion/MultipleMapSelector';
import { LinkSelector } from './LinkSelector';

const useLength = (features: Feature[]) => {
  return useMemo(() => {
    return features
      .map((it) => length(it, { units: 'meters' }))
      .reduce((a, b) => a + b, 0);
  }, [features]);
};

interface Props {
  resetSelectorType: VoidFunction;
}

export const LinkSelectorPage = ({ resetSelectorType }: Props) => {
  const [menu] = useMenu();
  const isLinkSelected = menu.links.length > 0;
  const limits = LimitsApi.use();
  const selectedLength = useLength(menu.links);
  const [showRadius, setShowRadius] = useState(false);
  const [bufferRadiusInKilometers, setBufferRadiusInKilometers] = useState(
    menu.bufferRadiusInKilometers,
  );

  const coverage = useRoadCoverage({
    regions: menu.links,
    type: AnalysisType.SelectedLink,
    mapType: menu.mapType,
    mapVersion: menu.mapVersion,
    bufferRadiusInKilometers: menu.bufferRadiusInKilometers,
  });

  const validation = useValidation(
    [
      ...selectedLinkRequirements,
      ...nameRequirements,
      {
        label:
          'Regions area exceeded the report limit. Edit regions to see pricing coverage',
        check: () => coverage.limitExceeded,
      },
      {
        label: `Link length exceeds ${LINK_MAX_LENGTH_IN_METERS} meters`,
        check: () => selectedLength > LINK_MAX_LENGTH_IN_METERS,
      },
      {
        label: `Selected link is outside allowed area`,
        check: () => {
          if (limits?.limits?.allowedArea === undefined) return false;

          const someLinkIsOutside =
            menu.links.find((it) =>
              regionOutside(it, limits.limits.allowedArea),
            ) !== undefined;

          return someLinkIsOutside;
        },
      },
    ],
    { menu },
    () => {},
  );

  /**
   * Same DSegs on different map versions might have different geometries, hence it should not be possible to change the version if any links are already selected on the map.
   * User should remove all links and then change the map version.
   */
  const isMapVersionDisabled = useMemo((): boolean => menu.links.length > 0, [
    menu.links.length,
  ]);
  const mapVersionDisabledReason = useMemo(
    () =>
      isMapVersionDisabled
        ? 'It is not possible to change map version if links are already selected. In order to change the map version, first delete all links.'
        : undefined,
    [isMapVersionDisabled],
  );

  return (
    <EditSelectedLinkPageTemplate
      validation={validation}
      asideSection={
        isLinkSelected ? (
          <FormGroup>
            <LinkSettings
              showRadius={showRadius}
              setShowRadius={setShowRadius}
              selectedLength={selectedLength}
              setBufferRadiusInKilometers={setBufferRadiusInKilometers}
              bufferRadiusInKilometers={bufferRadiusInKilometers}
            />
          </FormGroup>
        ) : (
          <EmptyPlaceholder
            type="route"
            subtitle="Click on a road segment to select a link"
          />
        )
      }
      links={menu.links}
      glMapSection={
        <>
          <MapControl style={{ bottom: '0px' }}>
            <MultipleMapSelector
              disabled={isMapVersionDisabled}
              disabledReason={mapVersionDisabledReason}
            />
          </MapControl>
          <RoadCoverage coverage={coverage} />
          <LinkSelector
            resetSelectorType={resetSelectorType}
            showRadius={showRadius}
            bufferRadiusInKilometers={bufferRadiusInKilometers}
            setBufferRadiusInKilometers={setBufferRadiusInKilometers}
          />
        </>
      }
    />
  );
};
