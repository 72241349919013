/* eslint-disable @typescript-eslint/no-this-alias */
import { useAnalysisContext } from 'components/AnalysisViewPage/AnalysisViewPage';
import { useScenario } from 'components/AnalysisViewPage/MapFlowsPro/logic/scenario';
import ViewPageContent from 'components/AnalysisViewPage/ViewPageContent/ViewPageContent';
import Title from 'components/Layout/Title';
import GlMap from 'components/Map/GlMap';
import { MapScreenshotButton } from 'components/Map/MapScreenshotButton';
import { centerOnRegions } from 'components/Map/mapUtils';
import DateTimeSelector from 'components/UI/DateTimeSelector/DateTimeSelector';
import useMap from 'hooks/useMap';
import { analysisUrl } from 'logic/analysis/analysisUtils';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Button, FormGroup, RadioGroup, Text, useToasts } from 'tombac';
import {
  BackIcon,
  ChevronSDownIcon,
  ChevronSLeftIcon,
  ChevronSUpIcon,
  FiltersIcon,
} from 'tombac-icons';
import { DownloadSelectedLink } from './DownloadSelectedLink';
import { TreeType } from './LinkNode';
import { Palette, PaletteScale } from './palette/ColorPalette';
import { ColorPalettePicker } from './palette/ColorPalettePicker';
import { defaultColorPalettes } from './palette/defaultColorPalettes';
import { SelectedLinkTree, SelectedSegment } from './SelectedLinkTree';
import { SliderInput } from './SliderInput';
import { useMemo, useState } from 'react';
import DisplayRegions from 'components/Map/Layers/DisplayRegions';
import { useSelectedLinkResult } from './useSelectedLinkResult';
import {
  defaultSelectedLinkView,
  findScaleMaxPercent,
  isSelectedLinkRegion,
} from './utils';
import { useIsAdmin } from 'user';
import { ShowDebugTracesButton } from './Debug/ShowDebugTracesButton';

const MapCornerContainer = styled.div`
  width: 450px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #fff;
  border-right: solid 1px #e5e5e5;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
`;

function CollapsibleSection({ children, label }) {
  const [collapsed, setCollapsed] = React.useState(false);

  return (
    <ControlsSection style={{ padding: 0 }}>
      <Button
        $borderRadius="0"
        $height="60px"
        $pl="2sp"
        $pr="2sp"
        labelProps={{ $width: '100%' }}
        onClick={() => setCollapsed(!collapsed)}
        variant="flat"
      >
        <Box $alignItems="center" $display="flex" $width="100%">
          {label}
          <Box $ml="auto">
            {collapsed ? <ChevronSDownIcon /> : <ChevronSUpIcon />}
          </Box>
        </Box>
      </Button>
      {!collapsed && (
        <Box $p="2sp" $pt="0" $display="flex" $flexDirection="column">
          {children}
        </Box>
      )}
    </ControlsSection>
  );
}

const ControlsSection = styled.div<{ white?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${(p) => (p.white ? '#FFF' : '#fbfbfb')};
  border-bottom: solid 1px #e5e5e5;
`;

const ToggleButton = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  position: absolute;
  right: -32px;
  top: 20px;
  width: 17px;
`;

export enum SelectedLinkUnit {
  Trips,
  Percents,
}

export enum View {
  INCOMING,
  OUTGOING,
  ALL,
}

function WithResult() {
  const { analysis } = useAnalysisContext();
  const { scenario, setScenario } = useScenario();
  const isAdmin = useIsAdmin();
  const [minPercent, setMinPercent] = useState(1.5);
  const [minTrips, setMinTrips] = useState(50);
  const [menuHidden, setMenuHidden] = useState(false);
  const [view, setView] = useState<View>(defaultSelectedLinkView(analysis));
  const [selectedSegmentsIn, setSelectedSegmentsIn] = useState<
    SelectedSegment[]
  >(() => []);
  const [selectedSegmentsOut, setSelectedSegmentsOut] = useState<
    SelectedSegment[]
  >(() => []);
  const { search } = useLocation();

  const treeIn = useSelectedLinkResult(
    analysis,
    scenario.dateRange,
    scenario.timeRange,
    TreeType.In,
  );
  const treeOut = useSelectedLinkResult(
    analysis,
    scenario.dateRange,
    scenario.timeRange,
    TreeType.Out,
  );

  const isSelectedLinkRegionAnalysis = useMemo(() => {
    return isSelectedLinkRegion(analysis);
  }, [analysis]);

  React.useEffect(() => {
    setSelectedSegmentsIn([]);
    setSelectedSegmentsOut([]);
  }, [scenario.dateRange, scenario.timeRange]);

  const unitOptions = [
    { label: 'Percent', value: SelectedLinkUnit.Percents },
    { label: 'Trips', value: SelectedLinkUnit.Trips },
  ];
  const [palette, setPalette] = React.useState(() => defaultColorPalettes[1]);
  const [scale, setScale] = React.useState(PaletteScale.makeMinMax(0, 100, 10));

  React.useEffect(() => {
    if (treeIn && treeOut && isSelectedLinkRegionAnalysis) {
      setScale(
        PaletteScale.makeMinMax(
          0,
          findScaleMaxPercent(view === View.INCOMING ? treeIn : treeOut),
          10,
        ),
      );
    }
  }, [treeIn, treeOut]);

  const [unit, setUnit] = React.useState(SelectedLinkUnit.Percents);

  const getColor = React.useCallback(
    (value: number) => Palette.getColor(palette, scale, value),
    [palette, scale],
  );

  const toast = useToasts();
  React.useEffect(() => {
    if (!treeIn || treeIn.trips !== 0) return;
    if (!treeOut || treeOut.trips !== 0) return;

    toast.addToast('No data to display', 'alert', {
      id: 'selected-link-no-data',
      autoDismiss: false,
      content: (
        <>
          No trips were observed for this link with selected date/time
          parameters
        </>
      ),
    });

    return () => {
      toast.dismissToast('selected-link-no-data');
    };
  }, [treeIn, treeOut]);

  const backPathname = analysisUrl(
    analysis.info.type,
    analysis.info.id,
    location.pathname.includes('/share/') ? 'share' : 'view',
  );

  React.useEffect(() => {
    if (!(treeIn && treeOut)) return;
    setMinTrips(
      Math.floor(Math.max(treeIn.trips, treeOut.trips) * (minPercent / 100)),
    );
  }, [treeIn, treeOut]);

  const map = useMap();
  React.useEffect(() => {
    centerOnRegions(map, analysis.regions);
  }, []);

  const clearSelectedSegments = () => {
    setSelectedSegmentsIn([]);
    setSelectedSegmentsOut([]);
  };

  let viewOptions = [
    { value: View.INCOMING, label: 'Incoming' },
    { value: View.OUTGOING, label: 'Outgoing' },
  ];

  if (!isSelectedLinkRegionAnalysis) {
    viewOptions = [{ value: View.ALL, label: 'All' }, ...viewOptions];
  }

  return (
    <>
      <MapScreenshotButton filename={analysis.info.name} />
      <MapCornerContainer
        style={{
          transform: menuHidden ? 'translateX(-450px)' : 'translateX(0px)',
        }}
      >
        <ToggleButton
          onClick={() => setMenuHidden(!menuHidden)}
          shape="circle"
          size="s"
          variant="accent"
        >
          <ChevronSLeftIcon
            style={{
              transform: menuHidden ? 'rotate(180deg)' : 'rotate(0)',
              transitionDuration: '0.2s',
              transitionTimingFunction: 'ease-in-out',
            }}
          />
        </ToggleButton>
        <ControlsSection white>
          <Box $display="flex" $justifyContent="space-between">
            <Link to={backPathname + search}>
              <Button
                $ml="-1.5sp"
                prepend={<BackIcon />}
                prependedHoverEffect="anim-backward"
                size="s"
                variant="flat"
              >
                Back
              </Button>
            </Link>
            <Box>
              {isAdmin && analysis.info.tripsDebug && (
                <ShowDebugTracesButton
                  analysis={analysis}
                  scenario={scenario}
                />
              )}
              <DownloadSelectedLink scenario={scenario} analysis={analysis} />
            </Box>
          </Box>
          <Text altFont fontSize={16} fontWeight="bold" $mt="1.5sp">
            {analysis.info.name}
          </Text>
        </ControlsSection>
        <ControlsSection>
          <DateTimeSelector
            scenario={scenario}
            onChange={setScenario}
            analysis={analysis}
            row
          />
        </ControlsSection>
        <CollapsibleSection
          label={
            <>
              <FiltersIcon />
              <Text
                $color="inherit"
                $ml="1sp"
                altFont
                as="div"
                fontWeight="bold"
                fontSize={14}
              >
                Filters
              </Text>
            </>
          }
        >
          <FormGroup
            label="Trips direction through the selected link"
            $mb="2sp"
          >
            <RadioGroup
              variant="horizontal"
              options={viewOptions}
              value={viewOptions.find((it) => it.value === view)}
              onChange={(e) => {
                clearSelectedSegments();
                setView(e.value as any);
              }}
            />
          </FormGroup>
          <FormGroup label="Values">
            <RadioGroup
              variant="horizontal"
              options={unitOptions}
              value={unitOptions.find((it) => it.value === unit)}
              onChange={(e) => setUnit(e.value)}
            />
          </FormGroup>
          {unit === SelectedLinkUnit.Percents && (
            <FormGroup label="Min Percent" $mt="2sp">
              <SliderInput
                min={0}
                max={5}
                step={0.1}
                value={minPercent}
                onChange={setMinPercent}
              />
            </FormGroup>
          )}
          {unit === SelectedLinkUnit.Trips && treeIn && treeOut && (
            <FormGroup label="Min Trips" $mt="2sp">
              <SliderInput
                min={0}
                max={Math.max(treeIn.trips, treeOut.trips)}
                step={1}
                value={minTrips}
                onChange={setMinTrips}
              />
            </FormGroup>
          )}
        </CollapsibleSection>
      </MapCornerContainer>
      {treeIn && treeOut && (
        <ColorPalettePicker
          palette={palette}
          onPaletteChange={setPalette}
          scale={scale}
          onScaleChange={setScale}
          unitLabel="%"
        />
      )}

      {(view === View.ALL || view === View.INCOMING) &&
        treeIn !== undefined &&
        selectedSegmentsOut.length === 0 && (
          <SelectedLinkTree
            tree={treeIn}
            minPercent={minPercent}
            minTrips={minTrips}
            getColor={getColor}
            unit={unit}
            selectedSegments={selectedSegmentsIn}
            setSelectedSegments={setSelectedSegmentsIn}
            isSelectedLinkRegion={isSelectedLinkRegionAnalysis}
            view={view}
          />
        )}
      {(view === View.ALL || view === View.OUTGOING) &&
        treeOut !== undefined &&
        selectedSegmentsIn.length === 0 && (
          <SelectedLinkTree
            tree={treeOut}
            minPercent={minPercent}
            minTrips={minTrips}
            getColor={getColor}
            unit={unit}
            selectedSegments={selectedSegmentsOut}
            setSelectedSegments={setSelectedSegmentsOut}
            isSelectedLinkRegion={isSelectedLinkRegionAnalysis}
            view={view}
          />
        )}
      <DisplayRegions
        onSelect={() => {
          clearSelectedSegments();
        }}
        dtoRegions={analysis.regions}
      />
    </>
  );
}

export function SelectedLinkPage() {
  return (
    <ViewPageContent>
      <Title>Selected Link</Title>
      <GlMap mapStyle={'basic_mono'} controlLocation="bottom-right">
        <WithResult />
      </GlMap>
    </ViewPageContent>
  );
}
