import { AddRouteIcon } from 'tombac-icons';
import {
  ActionButton,
  ActionButtonsContainer,
  StyledInactiveButton,
} from './SelectedLink.style';
import { TooltipOnHover } from 'tombac';
import { Feature, LineString } from '@turf/turf';

interface Props {
  resetSelectorType: VoidFunction;
  selected: Feature<LineString>[];
  opposite: Feature<LineString> | undefined;
  deleteAllClickHandle: VoidFunction;
  oppositeClickHandle: VoidFunction;
}

export const LinkSelectorActions = ({
  resetSelectorType,
  selected,
  opposite,
  deleteAllClickHandle,
  oppositeClickHandle,
}: Props) => {

  return (
    <ActionButtonsContainer>
      <StyledInactiveButton variant="tertiary">
        <AddRouteIcon />
      </StyledInactiveButton>
      {selected.length > 0 ? (
        <>
          <ActionButton variant="flat" onClick={deleteAllClickHandle}>
            Delete all
          </ActionButton>

          {opposite !== undefined ? (
            <ActionButton variant="flat" onClick={oppositeClickHandle}>
              Reverse direction
            </ActionButton>
          ) : (
            <TooltipOnHover
              content="Can't reverse one-way road"
              variant="inverted"
              size="s"
            >
              <ActionButton variant="flat" disabled>
                Reverse direction
              </ActionButton>
            </TooltipOnHover>
          )}
        </>
      ) : (
        <ActionButton variant="flat" onClick={resetSelectorType}>
          Cancel
        </ActionButton>
      )}
    </ActionButtonsContainer>
  );
};
