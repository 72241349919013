import { useMenu } from 'reducers/menuReducer';
import styled from 'styled-components';
import { Box, Caption, Checkbox, Label, tombac } from 'tombac';
import { HelpIcon } from 'tombac-icons';
import './EditSelectedLink.css';
import { Progress } from './Progress';
import { SliderInput } from 'components/SelectedLinkPage/SliderInput';
import { SimpleTooltip } from 'components/AnalysisTable/SimpleTooltip';
import { SelectedLinkSettingsContainer } from './SelectedLink.style';

export const LINK_MAX_LENGTH_IN_METERS = 2_000;
const MIN_LINK_RADIUS_IN_KILOMETERS = 5;
const MAX_LINK_RADIUS_IN_KILOMETERS = 50;

export const LinkRadiusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tombac.space(2)};
`;

export const LinkRadiusLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  showRadius: boolean;
  setShowRadius: (showRadius: boolean) => void;
  selectedLength: number;
  setBufferRadiusInKilometers: (bufferRadiusInKilometers: number) => void;
  bufferRadiusInKilometers: number;
}

export const LinkSettings = ({
  showRadius,
  setShowRadius,
  selectedLength,
  setBufferRadiusInKilometers,
  bufferRadiusInKilometers,
}: Props) => {
  const [, setMenu] = useMenu();

  return (
    <SelectedLinkSettingsContainer>
      <Progress
        label="Segments length"
        value={Math.floor(selectedLength)}
        max={LINK_MAX_LENGTH_IN_METERS}
        unit="meters"
      />
      <LinkRadiusContainer>
        <LinkRadiusLabel>
        <Label
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              alignItems: 'center',
            }}
          >            Radius{' '}
            <SimpleTooltip content="The radius of trips from the link that can be analyzed.">
              <HelpIcon />
            </SimpleTooltip>
          </Label>
          <Checkbox
            label={
              <Caption $color={tombac.color('neutral', 700)}>
                Show radius
              </Caption>
            }
            size="xs"
            variant="toggle"
            checked={showRadius}
            labelPlacement="before"
            onChange={() => setShowRadius(!showRadius)}
          />
        </LinkRadiusLabel>{' '}
        <Box>
          <SliderInput
            min={MIN_LINK_RADIUS_IN_KILOMETERS}
            max={MAX_LINK_RADIUS_IN_KILOMETERS}
            value={bufferRadiusInKilometers}
            unit="km"
            onChange={(v) => setBufferRadiusInKilometers(Math.floor(v))}
            onAfterChange={(v) => {
              setMenu({
                bufferRadiusInKilometers: Math.floor(v),
              });
            }}
          />
        </Box>
      </LinkRadiusContainer>
    </SelectedLinkSettingsContainer>
  );
};
