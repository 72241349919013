import LimitsApi from 'api/LimitsApi';
import { Kilometers } from 'components/UI/Kilometers/Kilometers';
import { AnalysisType } from 'model/AnalysisDto';
import { useMenu } from 'reducers/menuReducer';
import styled from 'styled-components';
import { Caption, Heading, tombac } from 'tombac';

const DescriptionContainer = styled.div`
  width: ${tombac.unit(340)};
  padding: ${tombac.space(4)};
  display: flex;
  flex-direction: column;
  gap: ${tombac.space(4)};
  flex-shrink: 0;
`;

const DescriptionText = styled(Caption)`
  font-family: Proxima Nova;
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
`;

const DefaultDescriptionText = styled(DescriptionText)`
  font-weight: 400;
  color: ${tombac.color('neutral', 700)};
`;

const BoldDescriptionText = styled(DescriptionText)`
  font-weight: 600;
`;

const ListContainer = styled.ul`
  margin: 0 0 0 ${tombac.space(2)};
  padding: 0;
`;

const SelectedLinkRoadCoverageDescription = () => (
  <>
    <DefaultDescriptionText>
      <BoldDescriptionText>
        This buffer of roads is not related to counting trips at all.
      </BoldDescriptionText>{' '}
      It’s only for informative purposes to calculate the price of your report
      based on FRC (Functional Road Classes) from
      <BoldDescriptionText $color="#00A65E"> 0 to 5</BoldDescriptionText> and
      the regions defined in your analysis.
    </DefaultDescriptionText>
    <DefaultDescriptionText>
      For Selected Link reports with a radius bigger than 10 km from the link,
      pricing coverage value is calculated based on road classes from
      <BoldDescriptionText $color="#00A65E"> 0 to 5</BoldDescriptionText> (for
      the first 10 km) and then additionally based on road classes from
      <BoldDescriptionText $color="#FF5D00"> 0 to 2</BoldDescriptionText> (for
      the area above 10 km)
    </DefaultDescriptionText>
    <DefaultDescriptionText>
      Example for selected radius of 25 km: <br />
      We sum up two values of pricing coverage:
      <ListContainer>
        <li>
          For the first 10 km based on FRCs{' '}
          <BoldDescriptionText $color="#00A65E"> 0 to 5</BoldDescriptionText>
        </li>
        <li>
          The remaining 15 km based on FRC{' '}
          <BoldDescriptionText $color="#FF5D00"> 0 to 2</BoldDescriptionText>{' '}
          only.
        </li>
      </ListContainer>
    </DefaultDescriptionText>
    <DefaultDescriptionText>
      Please note that the selected link query will still investigate all road
      classes.
    </DefaultDescriptionText>
  </>
);

const SelectedLinkAsRegionRoadCoverageDescription = () => (
  <>
    <DefaultDescriptionText>
      <BoldDescriptionText>
        This buffer of roads is not related to counting trips at all.
      </BoldDescriptionText>{' '}
      It’s only for informative purposes to calculate the price of your report
      based on FRC (Functional Road Classes) from
      <BoldDescriptionText $color="#00A65E"> 0 to 5</BoldDescriptionText> and
      the regions defined in your analysis.
    </DefaultDescriptionText>
    <DefaultDescriptionText>
      Please note that the selected link query will still investigate all road
      classes.
    </DefaultDescriptionText>
    <DefaultDescriptionText>
      The maximum radius for the Polygon geometry{' '}
      <BoldDescriptionText>in the Selected Link report </BoldDescriptionText> <br />
      is 10 km. As a result, the Pricing Coverage is calculated based on this
      limit.
    </DefaultDescriptionText>
  </>
);

const FlowMatrixRoadCoverageDescription = () => (
  <>
    <DefaultDescriptionText>
      <BoldDescriptionText>
        This buffer of roads is not related to counting trips at all.
      </BoldDescriptionText>{' '}
      It’s only for informative purposes to calculate the price of your report
      based on FRC (Functional Road Classes) from
      <BoldDescriptionText $color="#00A65E"> 0 to 5</BoldDescriptionText> and
      the regions defined in your analysis.{' '}
    </DefaultDescriptionText>
  </>
);
interface Props {
  coverage: number;
}

export const RoadCoverageDescription = ({ coverage }: Props) => {
  const [menu] = useMenu();
  return (
    <DescriptionContainer>
      <Heading level={2} fontWeight={'bold'}>
        Pricing coverage
      </Heading>
      <DescriptionText fontWeight={700}>
        Length: <Kilometers squared={false} value={coverage} />
      </DescriptionText>
      {menu.type === AnalysisType.FlowMatrix ? (
        <FlowMatrixRoadCoverageDescription />
      ) : menu.regions.length > 0 && menu.type === AnalysisType.SelectedLink ? (
        <SelectedLinkAsRegionRoadCoverageDescription />
      ) : (
        <SelectedLinkRoadCoverageDescription />
      )}
    </DescriptionContainer>
  );
};
