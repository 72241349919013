import { useMemo, useRef, useState } from 'react';
import * as React from 'react';
import { featureCollection } from '@turf/helpers';
import centroid from '@turf/centroid';
import { colorFillScale, colorTextScale, rangeCount } from './colorScale';
import ColorLegend from './ColorLegend';
import { RegionDto } from 'model/RegionDto';
import useLayers from 'hooks/useLayers';
import { ReadableResult } from 'logic/ReadableResult';
import { normalize, minMax, clamp, sum } from 'logic/math';
import { usePaintProperty } from 'hooks/usePaintProperty';
import { useMapEvent } from 'hooks/useMapEvent';
import { useLayerEvent } from 'hooks/useLayerEvent';
import { useFeatureState } from 'hooks/useFeatureState';
import useMap from 'hooks/useMap';
import { calculateArea } from './calculateArea';
import Popup from '../../Map/Layers/Popup';
import './MapFlows.css';
import { useLayoutProperty } from 'hooks/useLayoutProperty';
import { Button } from 'tombac';
import { isLayerLineString } from 'components/Map/mapUtils';

const regionName = (regions: RegionDto[], index: number) => {
  if (index > regions.length - 1) {
    return 'External';
  }
  return regions[index].properties.name;
};

interface MapFlowsLayersProps {
  regions: RegionDto[];
  selectedFrom?: number;
  selectedTo?: number;
  onHover: (id: number) => void;
  result?: ReadableResult;
  labels?: boolean;
  flowsPerKm?: boolean;
  hovered?: number;
  hideLegend?: boolean;
  setFromId?: (id: number) => void;
  setToId?: (id: number) => void;
}

const regionLayers: mapboxgl.Layer[] = [
  {
    id: 'polygon-fill',
    type: 'fill',
    paint: {
      'fill-color': [
        'match',
        ['get', 'i'],
        -1,
        'rgba(0,0,0,0.2)',
        'rgba(0,0,0,0.2)',
      ],
      'fill-opacity': ['case', isLayerLineString, 0, 0.65],
      'fill-outline-color': 'rgba(0,0,0,0)',
    },
  },
  {
    id: 'polygon-outline',
    type: 'line',
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    } as any,
    paint: {
      'line-color': [
        'case',
        ['==', ['feature-state', 'hovered'], true],
        'rgba(97, 173, 224, 1)',
        isLayerLineString,
        'rgba(0,0,0,0.5)',
        'rgba(255,255,255,0.4)',
      ],
      'line-width': ['case', isLayerLineString, 7, 1.5],
    },
  },
];

const centerLayers: mapboxgl.Layer[] = [
  {
    id: 'polygon-name',
    type: 'symbol',
    layout: {
      'text-field': '{text}',
      'text-font': ['Noto-Regular'],
      'text-offset': [0, 0.1],
      'text-size': ['get', 'text-size'],
      visibility: 'visible',
    } as any,
    paint: {
      'text-halo-color': 'rgba(255,255,255,0.9)',
      'text-halo-width': 1.5,
      'text-color': [
        'case',
        ['boolean', ['feature-state', 'hovered'], false],
        'rgba(97, 173, 224, 1)',
        ['get', 'text-color'],
      ],
    },
  },
];

const regionAsLineStringLayer: mapboxgl.Layer[] = [
  {
    id: 'line-string-border',
    type: 'line',
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    } as any,
    paint: {
      'line-color': 'rgba(0,0,0,0.5)',
      'line-width': 1.5,
      'line-gap-width': 7,
    },
  },
];

function MapFlowsLayer(props: MapFlowsLayersProps): any {
  const origin = props.selectedFrom;
  const destination = props.selectedTo;

  const [popup, setPopup] = useState<
    undefined | { index: number; coords: number[] }
  >(undefined);

  const regionCollection = useMemo(
    () =>
      featureCollection(props.regions.map((f, i) => ({ ...f, id: '' + i }))),
    [props.regions],
  );
  const regionsArea = useMemo(() => {
    return props.regions.map(calculateArea);
  }, [props.regions]);

  const [percents, normalizedFlows] = useMemo(() => {
    const { regions, selectedFrom, selectedTo, result, flowsPerKm } = props;

    if (selectedFrom === -1 && selectedTo === -1) {
      return [undefined, undefined];
    }

    const isVia = selectedFrom > -1 && selectedTo > -1;
    const oneToMany = selectedFrom > -1 && selectedTo === -1;
    const manyToOne = selectedFrom === -1 && selectedTo > -1;

    const absoluteFlows = regions.map((region, id) => {
      if (id === selectedFrom || id === selectedTo) {
        return 0;
      }

      if (isVia) {
        return result.get(selectedFrom, selectedTo, id).trips;
      }

      let areaDivisor = 1;
      if (flowsPerKm) {
        areaDivisor = regionsArea[id];
      }
      if (oneToMany) {
        return result.get(selectedFrom, id).trips / areaDivisor;
      }
      if (manyToOne) {
        return result.get(id, selectedTo).trips / areaDivisor;
      }
    });

    const flowSum = isVia
      ? result.get(selectedFrom, selectedTo).trips
      : sum(absoluteFlows);

    const percents = absoluteFlows.map((x) =>
      flowSum === 0 ? 0 : (x / flowSum) * 100,
    );

    return [percents, normalize(absoluteFlows)];
  }, [
    props.regions,
    origin,
    destination,
    props.result,
    regionsArea,
    props.flowsPerKm,
  ]);

  const internalFlows = useMemo(
    () => normalize(props.regions.map((r, i) => props.result.get(i, i).trips)),

    [props.regions, props.result],
  );

  const fillExpression = useMemo(() => {
    if (normalizedFlows === undefined) {
      return [
        'case',
        ['==', ['feature-state', 'hovered'], true],
        'rgba(148, 176, 197, 0.38)',
        'rgba(0,0,0,0.2)',
      ];
    }

    const fillValues: any[] = normalizedFlows
      .map((flows) =>
        flows === 0 ? 'rgba(0,0,0,0.05)' : colorFillScale(flows),
      )
      .map((color, id) => [id, color])
      .filter(([id]) => id !== origin && id !== destination)
      .reduce((a, b) => (a.push(...b), a), []);
    return ['match', ['get', 'i'], ...fillValues, '#888'];
  }, [normalizedFlows, origin, destination]);

  const centersList = useMemo(
    () => props.regions.map((region) => centroid(region)),
    [props.regions],
  );

  const textFeatures = useMemo(
    () =>
      featureCollection(
        props.regions.map((region, id) => {
          const scaleTextSize = (x: number) =>
            10 + 4 * (clamp(0, 100, x) / 100);
          const textSize =
            normalizedFlows === undefined
              ? scaleTextSize(internalFlows[id])
              : scaleTextSize(normalizedFlows[id]);

          const textColor =
            normalizedFlows === undefined
              ? '#333'
              : colorTextScale(normalizedFlows[id]);

          const isSelected = id === origin || id === destination;
          const selectedStyle = {
            'text-size': 13,
            'text-color': '#333',
          };

          let text = region.properties.name;
          if (normalizedFlows) {
            if (isSelected) {
              const role =
                origin === id && destination === id
                  ? 'Origin & Destination'
                  : origin === id
                  ? 'Origin'
                  : 'Destination';
              text = role + '\n' + region.properties.name;
            } else {
              text = percents[id] === 0 ? '' : percents[id].toFixed(2) + '%';
            }
          }

          return {
            ...centersList[id],
            id,
            properties: {
              text,
              'text-size': textSize,
              'text-color': textColor,
              ...(isSelected ? selectedStyle : {}),
            },
          };
        }),
      ),
    [props.regions, centersList, normalizedFlows, internalFlows, percents],
  );

  const lineStringRegionsCollection = useMemo(() => {
    return regionCollection.features.filter(
      (f) => f.geometry.type === 'LineString',
    );
  }, [regionCollection]);

  useLayers(
    'line-string-border',
    regionAsLineStringLayer,
    lineStringRegionsCollection,
  );
  useLayers('regions', regionLayers, regionCollection);
  useLayers('centers', centerLayers, textFeatures);

  usePaintProperty('polygon-fill', 'fill-color', fillExpression);
  usePaintProperty('polygon-outline', 'line-color', fillExpression);

  const clickStatus = React.useRef({ clickedRegion: false });
  const handleMouseClick = (e: any) => {
    const feature = e.features[0];
    setPopup({ index: feature.properties.i, coords: e.lngLat });
    clickStatus.current.clickedRegion = true;
  };

  useLayerEvent('click', 'polygon-fill', handleMouseClick);
  useLayerEvent('click', 'line-string-border', handleMouseClick);

  useMapEvent('click', () => {
    if (!clickStatus.current.clickedRegion) {
      setPopup(undefined);
    }
    clickStatus.current.clickedRegion = false;
  });

  const map = useMap();
  const internalHovered = useRef(-1);

  const handleMouseMove = (e: any) => {
    const i = e.features[0].properties.i;
    if (i !== internalHovered.current) {
      (map as any)._canvas.style.cursor = 'pointer';
      internalHovered.current = i;
      props.onHover(i);
    }
  };

  useLayerEvent('mousemove', 'polygon-fill', handleMouseMove);
  useLayerEvent('mousemove', 'polygon-outline', handleMouseMove);

  const handleMouseLeave = () => {
    if (internalHovered.current !== -1) {
      (map as any)._canvas.style.cursor = 'unset';
      internalHovered.current = -1;
      props.onHover(-1);
    }
  };

  useLayerEvent('mouseleave', 'polygon-fill', handleMouseLeave);
  useLayerEvent('mouseleave', 'polygon-outline', handleMouseLeave);

  useFeatureState(
    'regions',
    props.hovered === -1 ? undefined : '' + props.hovered,
    { hovered: true },
    { hovered: false },
  );

  useFeatureState(
    'centers',
    props.hovered === -1 ? undefined : '' + props.hovered,
    { hovered: true },
    { hovered: false },
  );

  useLayoutProperty(
    'polygon-name',
    'visibility',
    props.labels ? 'visible' : 'none',
  );

  const setFromId = (index: number) => {
    if (props.setFromId) {
      props.setFromId(index);
      setPopup(undefined);
    }
  };
  const setToId = (index: number) => {
    if (props.setToId) {
      props.setToId(index);
      setPopup(undefined);
    }
  };

  const { min, max } = minMax(percents);

  const hideLegend =
    props.hideLegend || (props.selectedFrom === -1 && props.selectedTo === -1);

  const isOneToMany = props.selectedFrom > -1 && props.selectedTo === -1;
  const isManyToOne = props.selectedFrom === -1 && props.selectedTo > -1;
  const isVia = props.selectedFrom > -1 && props.selectedTo > -1;

  const fromName =
    props.selectedFrom > -1
      ? regionName(props.regions, props.selectedFrom)
      : '';
  const toName =
    props.selectedTo > -1 ? regionName(props.regions, props.selectedTo) : '';
  const popupName =
    popup !== undefined ? regionName(props.regions, popup.index) : '';
  const popupPercent =
    popup !== undefined && percents !== undefined
      ? percents[popup.index].toFixed(2)
      : undefined;

  const popupRegionIsSelected =
    popup !== undefined &&
    (popup.index === props.selectedFrom || popup.index === props.selectedTo);

  return (
    <>
      {popup !== undefined && (
        <Popup
          position={popup.coords}
          offset={[0, -10]}
          key={JSON.stringify(popup.coords)}
        >
          <div className="MapFlows-popup">
            <div className="MapFlows-popup-title">{popupName}</div>
            {popupPercent && !popupRegionIsSelected && (
              <div className="MapFlows-popup-description">
                {isOneToMany && (
                  <>
                    <b>{popupPercent}%</b> of all trips that started in{' '}
                    {fromName} ended in this region
                  </>
                )}
                {isManyToOne && (
                  <>
                    <b>{popupPercent}%</b> of all trips that ended in {toName}{' '}
                    started in this region
                  </>
                )}
                {isVia && (
                  <>
                    <b>{popupPercent}%</b> of all trips that started in{' '}
                    {fromName} and ended in {toName} went through this region
                  </>
                )}
              </div>
            )}
            <div className="MapFlows-popup-actions">
              <Button $m="0.5sp 1sp" onClick={() => setFromId(popup.index)}>
                Set as origin
              </Button>
              <Button $m="0.5sp 1sp" onClick={() => setToId(popup.index)}>
                Set as destination
              </Button>
              {(popup.index === props.selectedTo ||
                popup.index === props.selectedFrom) && (
                <Button
                  $m="0.5sp 1sp"
                  onClick={() =>
                    popup.index === props.selectedTo
                      ? setToId(-1)
                      : setFromId(-1)
                  }
                >
                  Clear
                </Button>
              )}
            </div>
          </div>
        </Popup>
      )}
      {}
      {!hideLegend && (
        <ColorLegend min={min} max={max} rangeCount={rangeCount} />
      )}
    </>
  );
}

export default React.memo(MapFlowsLayer);
